.community-carousel {
  p {
    width: 100% !important;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 225px;
  }
  a {
    float: left;
    border: 1px solid black;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat";
    padding: 8px;
    color: black;
    text-decoration: none;
  }

  & > div > div {
    transition: all 600ms $easeInOutQuart;
    padding-bottom: 50px;
    opacity: 0;
  }

  &.is-up > div > div {
    &:nth-child(2n + 1) {
      padding-top: 50px;
      padding-bottom: 0;
    }
  }

  &.is-down > div > div {
    &:nth-child(2n + 2) {
      padding-top: 50px;
      padding-bottom: 0;
    }
  }

  &.is-opaque > div > div {
    opacity: 1;
  }

  .slide-img-container {
    position: relative;
    height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    @include breakpoint(max, sm) {
      height: 67vw;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;

      height: 100%;
      width: auto;

      transform: translate(-50%, -50%);
    }
  }
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px)  { 
    .slide-img-container {
       width: 23vw !important; 
      height: 23vw !important;
      padding: 0px 10px;
    }
}