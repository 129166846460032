.align-center {
  text-align: center;
}

.font-gotham {
  font-family: 'Gotham';
}

.font-bold {
  font-weight: 700;
}

body {
  overflow-x: hidden;
  font-family: 'Montserrat';
  h1 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 5px;

    @include breakpoint(max, md) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.header-underline-left {
  float: left;
  border-bottom: 2px solid black;
  margin-right: 10px;
  width: 125px;
  height: 45px;
  flex: 1 1 auto;
  max-width: 125px;

  @include breakpoint(max, sm) {
    display: none;
  }
}

.header-underline-right {
  display: inline-flex;
  border-bottom: 2px solid black;
  margin-left: 10px;
  flex: 1 1 auto;
  max-width: 125px;
  height: 45px;
}

.section--header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 15px 0;

  span {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    margin-bottom: 10px;
    border-bottom: 2px solid black;
  }
}

.social-icon-container img {
  height: 40px;
}

.icons-container {
  margin-top: 50px;
  img {
    width: 150px;
    margin: 15px;
  }
}

.yellow-info-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 150px;
  width: 150px;
  margin: 0 auto;

  background-color: #fcb02a;
  border-radius: 50%;
  text-align: center;

  h1 {
    margin: 0;
    letter-spacing: 0;
    font-size: 2rem;
  }

  p {
    font-weight: 600;
    line-height: 14px;
    width: 95%;
    margin: 0;
  }

  img {
    padding-top: 5px;
    width: auto;
    height: 50px;
  }
}

button {
  background: white;
}

// Hide IE Dropdown
select::-ms-expand {
  display: none;
}
