.section--experience-menu {
  position: absolute;
  z-index: 1;
  top: 0;

  width: 100%;

  .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
  }

  .container-header-experience {
    overflow: hidden;
  }

  .header-experience {
    padding-top: 125px;
    span {
      color: white;
      // padding-left: 20px;
      padding-left: 135px;
    }

    .header-underline-right {
      width: 125px;
    }
  }

  .subpage-experience {
    padding-top: 225px;
    padding-bottom: 10px;
    padding-left: 0;
    line-height: 18px;

    p {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      color: white;
      font-weight: 400;
      letter-spacing: 2px;
    }

    .header-underline-left {
      height: 15px;
      border-bottom: 1px solid white;
      max-width: 200px;
      transform-origin: left;
      width: unset;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }
}

.section--experience-menu-minimal {
  width: 100vw;
  margin-top: 100px;
  margin-bottom: 20px;

  .experience-select {
    display: inline;
    select {
      box-shadow: none;
      user-select: none;
      border: none;
      outline: none !important;

      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      width: 100%;
      padding: 10px 15px;

      font-size: 1.2rem;

      color: white;

      option {
        color: black;
      }
    }
  }

  .experience-select:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
  }

  .experience-select:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 20px;
    right: 30px;
    border-top: 10px solid white;
  }

  select::-ms-expand {
    display: none;
}
}

.section--all-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 30px;

  @include breakpoint(max, md) {
    margin-top: 0;
    padding-left: 0;
  }

  .line-texture-container {
    overflow: hidden;
    position: relative;
    padding-top: 15%;
    padding-left: 10%;

    @include breakpoint(max, md) {
      padding: 0;
    }

    .line-texture-img {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .all-lifestyle-img-container {
    height: 600px;
    width: 85%;
    margin-left: auto;

    background-size: cover;
    background-position: 75%;

    @include breakpoint(max, md) {
      width: 100%;
      height: 400px;
    }
  }

  .all-village-img-container {
    overflow: hidden;
    img {
      width: 100%;
      position: relative;
      display: block;
      // top: -10px;
      // left: -10px;
    }
  }

  .all-commercial-img-container {
    img {
      width: 100%;
      position: relative;
      display: block;
      // top: -10px;
      // left: -10px;
    }
  }

  .all-residential-img-container {
    img {
      width: 100%;
      position: relative;
      display: block;
      // top: -10px;
      // left: -10px;
    }
  }

  .all-community-img-container {
    img {
      width: 100%;
      position: relative;
      display: block;
      // top: -10px;
      // left: -10px;
    }
  }

  // .yellow-sub-container {
  //   background: #ffc600;
  // }

  // .blue-sub-container {
  //   background: #009bdc;
  // }

  // .green-sub-container {
  //   background: #9ebf35;
  // }

  // .red-sub-container {
  //   background: #e7222d;
  // }

  .header-all-lifestyle {
    .header-underline-right {
      // border-bottom: 2px solid #ffc600;
      max-width: 100%;
    }
  }

  .header-all-village {
    margin-top: 30px;
    .header-underline-right {
      // border-bottom: 2px solid #9ebf35;
      margin-left: -45px;
      width: 100%;
      height: 160px;
    }
  }

  .header-all-commercial {
    margin-top: 30px;
    .header-underline-right {
      margin-right: -50px;
      // border-bottom: 2px solid #009bdc;
      max-width: 100%;
    }
  }

  .header-all-residential {
    margin-top: 30px;
    .header-underline-right {
      // border-bottom: 2px solid #9ebf35;
      max-width: 100%;
    }
  }

  .header-all-community {
    margin-top: 30px;
    .header-underline-right {
      margin-right: -50px;
      // border-bottom: 2px solid #e7222d;
      max-width: 100%;
      height: 102px;
      margin-left: -30px;
    }
  }

  a {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block;
  }
}

.section--lifestyle-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px;

  @include breakpoint(max, md) {
    margin-top: 0;
    padding: 0;
  }

  .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px;
  }

  .line-texture-bg {
    overflow: hidden;
    height: 650px;
    width: 40%;
    position: absolute;
    margin-left: -150px;
    z-index: -1;
  }

  .content-detail-container {
    background: white;
    border-top: 6px solid black;
    // background: #e0e1e3;
    padding-top: 35px;
    position: relative;

    .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  // img {
  //   width: 100%;
  //   height: 100%;
  // }

  .link-container {
    min-height: 100px;
    display: flex;
    align-items: center;

    a {
      border: 2px solid black;
      letter-spacing: 2px;
      font-size: 12px;
      font-family: "Montserrat";
      font-weight: 600;
      padding: 7px;
      color: black;
      text-decoration: none;
      display: inline-block;
      z-index: 1;
      position: absolute;
      bottom: 32px;
    }
  }
  a {
    text-decoration: none;
    color: black;
  }
}

.section--commercial-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px;

  @include breakpoint(max, md) {
    margin-top: 0;
    padding: 0;
  }

  .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px;
  }

  .line-texture-bg {
    overflow: hidden;
    height: 650px;
    width: 40%;
    position: absolute;
    margin-left: -150px;
    z-index: -1;
  }

  .content-detail-container {
    background: white;
    border-top: 6px solid black;
    // background: #e0e1e3;
    padding-top: 35px;
    position: relative;

    .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .link-container {
    min-height: 100px;
    display: flex;
    align-items: center;

    a {
      border: 2px solid black;
      letter-spacing: 2px;
      font-size: 12px;
      font-family: "Montserrat";
      font-weight: 600;
      padding: 7px;
      color: black;
      text-decoration: none;
      display: inline-block;
      z-index: 1;
      position: absolute;
      bottom: 32px;
    }
  }

  a {
    color: black;
    text-decoration: none;
  }

  .logos-container {
    width: calc(100% + 15px);
    margin-bottom: 25px;
  }
  .directory-item {
    .item--logo {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 150px;
      padding: 15px;

      border: 1px solid hsl(0, 0%, 90%);
      margin-top: -1px;
      margin-right: -1px;

      img {
        width: 70%;
      }
    }

    .item--content {
      position: relative;

      overflow: hidden;
      width: calc(400% + 1px);

      background: hsl(0, 0%, 95%);

      .content--inner {
        padding: 15px 40px;
      }
    }
  }

  .directory-item {
    background: white;
    @for $i from 1 through 4 {
      &:nth-child(4n + #{$i}) .item--content {
        left: #{-100% * ($i - 1)};
      }
    }
  }

  @media all and(max-width: 992px) {
    .directory-item {
      .item--content {
        width: calc(200% + 1px);
      }

      @for $i from 1 through 2 {
        &:nth-child(2n + #{$i}) .item--content {
          left: -100% * ($i - 1);
        }
      }
    }
  }
}

.section--residential-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px;

  @include breakpoint(max, md) {
    margin-top: 0;
    padding: 0;
  }

  .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px;
  }

  .line-texture-bg {
    overflow: hidden;
    height: 450px;
    width: 30%;
    position: absolute;
    margin-left: -150px;
    z-index: -1;
  }

  .content-detail-container {
    background: white;
    border-top: 6px solid black;
    // background: #e0e1e3;
    padding-top: 35px;
    position: relative;

    .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  // img {
  //   width: 100%;
  //   height: 100%;
  // }

  .link-container {
    min-height: 100px;
    display: flex;
    align-items: center;
  }

  a {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block;
    z-index: 1;
    position: absolute;
      bottom: 32px;
  }
}

.section--community-amenities-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px;

  @include breakpoint(max, md) {
    margin-top: 0;
    padding: 0;
  }

  .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px;
  }

  .line-texture-bg {
    overflow: hidden;
    height: 650px;
    width: 40%;
    position: absolute;
    margin-left: -150px;
    z-index: -1;
  }

  .content-detail-container {
    background: white;
    border-top: 6px solid black;
    // background: #e0e1e3;
    padding-top: 35px;
    position: relative;

    .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  // img {
  //   width: 100%;
  //   height: 100%;
  // }

  .link-container {
    min-height: 100px;
    display: flex;
    align-items: center;
  }

  a {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block;
    z-index: 1;
    position: absolute;
      bottom: 32px;
  }
}

.section--uoa-property-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px;

  @include breakpoint(max, md) {
    margin-top: 0;
    padding: 0;
  }

  #gmap-container {
    @include breakpoint(min, lg) {
      padding-left: 15px;
    }
    @include breakpoint(max, lg) {
      min-height: 400px;
    }
  }

  .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px;
  }

  .img-uoa {
    background: url('../img/experience-page/uoa/bg-uoa1-mobile.jpg'); 
    background-position-x: 15px !important;
    
    @include breakpoint(min, lg) {
      background: url('../img/experience-page/uoa/bg-uoa1.jpg'); 
    }
  }

  .line-texture-bg {
    overflow: hidden;
    height: 450px;
    width: 30%;
    position: absolute;
    margin-left: -150px;
    z-index: -1;
  }

  .content-detail-container {
    background: white;
    border-top: 6px solid black;
    // background: #e0e1e3;
    padding-top: 35px;
    position: relative;

    .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .line-texture-container {
    overflow: hidden;
    height: 50px;
    margin-top: 50px;
  }

  .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  // img {
  //   width: 100%;
  //   height: 100%;
  // }

  .link-container {
    min-height: 100px;
    display: flex;
    align-items: center;
  }

  a {
    color: black;
    text-decoration: none;
  }
}

.content-to-animate {
  opacity: 0;
}

h2 {
  font-family: "montserrat";
  font-weight: 600;
  letter-spacing: 5px;
}
