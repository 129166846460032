.rellax {
  transition: all 250ms $easeOutQuad;
}

.page-aboutus {
  div[class*="section--"] {
    margin: 10% 0;
  }

  .section--aboutus-splash {
    margin-top: 0 !important;
  }

  .section--header {
    margin-right: -100px;
    margin-left: 20px;
  }

  // hide all boxes on mobile
  @include breakpoint(max, md) {
    [class*="box-"],
    .line-texture {
      display: none;
    }

    .greendesign-bg,
    .msc-bg,
    .award-bg {
      width: 100% !important;
    }

    .section--header {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.section--aboutus-splash {
  img {
    width: 100%;
  }

  .column-img-container {
    max-height: 550px;
    overflow: hidden;
  }

  .line-texture {
    position: absolute;
    z-index: -1;
    right: 0;
    width: 30%;
    transform: translate(-20%, 50%);
  }

  .box--yellow {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    transform: translate(345%, -70%);
    width: 25%;
    height: 50%;
  }

  .aboutus--textarea {
    transform: translateX(-70px);
  }

  .white-mask {
    background: white;
    position: absolute;
    transform-origin: left;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
  }
  .box-white-mask {
    background: white;
    position: absolute;
    transform-origin: left;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
  }
}

.section--aboutus {
  .welcome-line {
    border-bottom: 2px solid black;
    height: 45px;
  }

  .header-text-box {
    max-width: 350px;
  }
}

.section--greendesign {
  .line-texture {
    position: absolute;
    z-index: -1;
    top: 10%;
    left: 0;

    width: 35%;
  }

  .greendesign-bg {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 90%;
    justify-content: center;
    max-height: 600px;
    overflow: hidden;

    @include breakpoint(max, md) {
      width: 100%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      img {
        width: 100%;
      }
    }
  }

  .box--green {
    position: absolute;
    top: 50%;
    right: 65%;
    z-index: -2;

    width: 35%;
    height: 55%;
    overflow: hidden;
  }

  li {
    font-size: 14px;
    font-weight: 400;
  }
}

.section--msc {
  .header--msc {
    span:first-child {
      margin-left: -40px;
      margin-right: 20px;

      height: 45px;
      border-bottom: 2px solid black;
    }
  }

  .msc-bg {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    width: 90%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      img {
        width: 100%;
      }
    }
  }

  .box--blue {
    position: absolute;
    z-index: -2;
    width: 35%;
    height: 55%;
    overflow: hidden;
    top: 30%;
    left: 65%;
  }

  .line-texture {
    position: relative;
    z-index: -1;
    top: 0%;
    left: 55%;

    width: 40%;
  }
}

.section--award {
  position: relative;

  .award-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    width: 90%;
    margin-left: auto;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      img {
        width: 100%;
      }
    }
  }

  .box--red {
    position: absolute;
    z-index: -2;
    width: 35%;
    height: 45%;
    overflow: hidden;
    top: 20%;
    right: 65%;
  }

  .line-texture {
    position: absolute;
    top: 10%;
    left: -10%;
    z-index: -1;

    width: 40%;
  }

  .header-underline-right {
    max-width: 200px;
  }
}
