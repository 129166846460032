//contactus Page
.section--contactus {
  img {
    width: 100%;
  }
  .column-img-container {
    position: relative;
    z-index: 5;
    max-height: 550px;
    overflow: hidden;
  }
  .line-texture {
    position: absolute;
    z-index: -1;
    right: 0;
    width: 35%;
    transform: translate(50%, -90%);
  }
  .box--blue {
    position: absolute;
    background: #009bdc;
    z-index: 4;
    transform: translate(205%, -50%);
    width: 35%;
    height: 65%;
    img {
      width: 100%;
      height: 100%;
      opacity: 0.2;
    }
    .gettingHereBtn {
      border: 2px solid black;
      letter-spacing: 3px;
      font-size: 14px;
      font-weight: 600;
      padding: 8px;
      color: black;
      text-decoration: none;
    }
  }
  .header--contactus {
    padding-top: 1.5%;
    h1 {
      font-size: 3rem;
      letter-spacing: 5px;
    }
  }
  .uoa-contacts-container {
    position: absolute;
    top: 112%;
    left: 0;

    @include breakpoint(max, lg) {
      display: none;
    }
  }
  .contact-us-container {
  }
  .white-mask {
    background: white;
    position: absolute;
    transform-origin: left;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
  }
  .box-white-mask {
    background: white;
    position: absolute;
    transform-origin: left;
    width: 100%;
    height: 101%;
    top: 0;
    left: 0px;
    z-index: 1;
  }
  a {
    color: black;
    text-decoration: none;
  }
  iframe {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.enquiry-form {
  form {
    .comments-group {
      margin-right: -15px;
    }
    .comments-box {
      height: 100px;
    }
    label {
      margin: 0;
      display: flex;
      align-items: center;
    }
    label,
    select,
    textarea,
    input {
      font-size: 14px;
      font-weight: 400;
      font-family: "Montserrat";
    }
  }
  label {
    @include breakpoint(max, sm) {
      margin-left: 5px !important;
    }
  }
  textarea {
    @include breakpoint(max, sm) {
      width: 95%;
    }
  }
  select,
  input {
    @include breakpoint(max, sm) {
      margin: 0px 20px;
    }
  }

  button {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
  }

  .form-check{
    @include breakpoint(max, sm){
      padding-left: 0;
    }
  }
}

.invalid-feedback {
}

.grecaptcha-badge {
  bottom: 25px !important;
}
