footer {
  position: relative;
  padding: 40px 15px;

  margin-top: 40px;

  @include breakpoint(max, md) {
    padding: 20px 15px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url("./../img/gray-texture.jpg");
    background-size: cover;
    background-position: center bottom;

    opacity: 0.3;
  }

  p.fine {
    margin: 0;
    margin-bottom: 5px;
    font-size: 0.7rem;
    text-align: center;

    a {
      color: black !important;
    }
  }
}

.footer--content {
  text-align: center;

  border-left: 1px solid black;
  border-right: 1px solid black;

  @include breakpoint(max, lg) {
    border: none;
  }

  p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 0.85rem;
  }

  .icons {
    span{
      cursor: pointer;
    }
    img {
      width: 50px;
    }
  }
}
