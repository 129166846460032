#menu-search-input {
  border-radius: 3px;
  border: 1px solid #ced4da;
  width: 0;
  transition: all 400ms ease-out;
  opacity: 0;
  transform-origin: right;
  &.is-active {
    opacity: 1 !important;
    width: 220px !important;
    margin-left: 8px;
    @media screen and (max-width: 365px){
      width: 175px !important;
    }
  }
}
#menu-search-btn {
  max-width: 100%;
  height: auto;
}
