//Getting here Page
.section--404-menu {
  position: absolute;
  top: 0;

  .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px;
  }

  .container-header-404 {
    overflow: hidden;

    .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px;
    }
  }

  .header-404 {
    overflow: hidden;
    padding-top: 125px;
    display: flex;
    span {
      color: white;
      padding-left: 102px;
    }

    .header-underline-right {
      width: 125px;
      @include breakpoint(max, lg) {
        display: none;
      }
    }
  }
}

.section--404-content {
  padding-top: 200px;
  padding-left: 245px;
  padding-right: 50px;
  // min-height: calc(100vh - 325px);
  min-height: 400px;
  @include breakpoint(max,md){
    margin-top: 100px;
    padding: 0px;
  }
  a {
    text-decoration: none;
    color: black;
  }
}
