.map-container,
.map-container2 {
  background: #e0e0e0;
  overflow: hidden;
  padding: 10px;
  height: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 500px;
  }

  img {
    width: 100%;
  }
  &:focus {
    outline: none;
  }
}

#map-container-inner,
#map-container-inner2 {
  height: 100%;
}

.zoom-btn-container,
.zoom-btn-container2 {
  position: absolute;
  top: 0;
  right: 30px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  img {
    width: 30px;
    padding-top: 5px;
  }
}

.routes-container,
.routes-container2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;

  display: none;

  .dashed {
    stroke-dasharray: 5;
  }
  .path {
    stroke-dasharray: 2000;
    stroke-dashoffset: 0;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.active,
  &.labels-only {
    display: block;
  }
}

.route-4 {
  svg:first-child {
    path {
      opacity: 0;
    }
  }
}

.route-btn {
  display: block;
  border: 2px solid black;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  padding: 3px;
  color: black;
  text-decoration: none;
  text-align: center;
  width: 100%;
  user-select: none;
  cursor: pointer;

  transition: all 400ms $easeOutQuart;

  &.active {
    background: black !important;
    color: white !important;
  }
}
