.section--shuttle-service-menu {
  position: absolute;
  top: 0;

  .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 295px;
  }

  .container-header-shuttle-service {
    overflow: hidden;

    .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px;
    }
  }

  .header-shuttle-service {
    overflow: hidden;
    padding-top: 125px;
    display: flex;
    @include breakpoint (max, sm){
      display: none !important;
    }
    // @media screen and (max-width: 450){
    //   font-size: 28px;
    // }
    span {
      color: white;
      padding-left: 57px;
      @media screen and (max-width: 480px){
      padding-left: 0px;
      }
    }

    .header-underline-right {
      width: 125px;
    }
  }

  .line-texture {
    background-image: url("../img/line-texture.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 30%;
    margin-left: 10%;
  }
}

.section--shuttle-service-content {
  width: 100%;
  z-index: -1;
  padding-top: 200px;
  padding-left: 225px;
  padding-right: 50px;

  @include breakpoint(max, md){
    padding-top: 300px;
    padding-left: 0;
    padding-right: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }
}
