//Parking Page
.section--parking-menu {
  position: absolute;
  top: 0;

  .texture-box {
    position: absolute;
    background-color: $green;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 295px;
  }

  .container-header-parking {
    overflow: hidden;

    .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px;
    }
  }

  .header-parking {
    overflow: hidden;
    padding-top: 80px;
    display: flex;
    span {
      color: white;
      padding-left: 45px;
      @media screen and (max-width: 480px) {
        padding-left: 10px;
      }
    }

    .header-underline-right {
      width: 125px;
      @include breakpoint(max, md) {
        height: 30px;
      }
    }
  }

  .line-texture {
    background-image: url('../img/line-texture.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 200px;
    z-index: -1;
    height: 400px;
    width: 30%;
    margin-left: 5%;
  }
}

a.button-more {
  border: 2px solid black;
  letter-spacing: 2px;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 7px;
  color: black;
  text-decoration: none;
  display: inline-block;
}

.navigation-tabs {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include breakpoint(max, md) {
    display: flex;
  }

  .nav-link {
    font-size: 1.2rem;
    font-weight: bold;
    color: lightgray;
    letter-spacing: 2px;
    padding: 1rem 2rem;
    border: 3px solid lightgray;
    border-radius: 0;
    position: relative;
    margin-left: -3px;
    transition: all 0.3s ease;

    @include breakpoint(max, md) {
      font-size: 1rem;
      margin-left: 0;
    }

    &:hover {
      color: gray;
      border-color: gray;
      z-index: 2;
    }
  }

  .nav-item {
    @include breakpoint(max, md) {
      width: 100%;
      margin-bottom: -3px;
    }
  }

  .nav-item a {
    height: 100%;
  }

  .nav-item:first-child .nav-link {
    margin-left: 0;
  }

  .nav-link.active {
    color: $green;
    border: 3px solid $green;
    z-index: 1;
  }
}

.page-parking .section--lifestyle-content {
  margin-top: 100px;
  padding-left: 245px;

  @include breakpoint(max, md) {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 300px;
  }

  .key-visual {
    max-width: 100%;

    @include breakpoint(min, md) {
      max-width: 110%;
      margin-left: -10%;
      position: relative;
    }
  }
}

.tab-content {
  ul,
  ol {
    font-size: 14px;
    padding-left: 0;
  }

  ul li {
    margin-left: 1.4rem;
  }
}

#tab-faq p {
  margin-bottom: 2rem;
}

.tab-inner {
  transition: all ease 0.3s;
}

.active .tab-inner {
  animation: showUp $easeOutQuart 0.6s;
}

@keyframes showUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.infographic-img {
  height: 180px;
}

#cashless-ticket .infographic-img {
  height: 220px;
}

.logo-container img {
  @include breakpoint(min, md) {
    max-width: 80px;
  }
  @include breakpoint(min, lg) {
    max-width: 100%;
  }
}

/* New class */
.steps-icons {
  height: 150px;
  width: auto;
  display: block;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 1rem;
  max-width: 100%;
}

$parking-orange: #fcb029;
$parking-green: #9ebf35;
$parking-colors: (
  'parking-green': $parking-green,
  'parking-orange': $parking-orange,
);

.border-rounded {
  border-radius: 5em;
  border-width: 2px;
  border-style: solid;
  padding: 1rem 2rem;
  text-align: center;
  font-weight: 700;
}

@each $name, $value in $parking-colors {
  .border-rounded--#{$name} {
    border-color: $value;
    color: $value;
  }
}

.page-parking h2 {
  letter-spacing: 1px;
}

.box-btn {
  border: 2px solid black;
  padding: 0.5rem 0.3rem;
  display: block;
  text-align: center;
  max-width: 200px;
}
