// Header
header {
  top: 0;
  z-index: 99;
  pointer-events: none;
  padding-right: 0 !important;
  .nav-toggle {
    pointer-events: all;
    cursor: pointer;
  }
  & > div:nth-child(1) {
    padding-right: 10px;
  }
  & > div:nth-child(1).is-active {
    background: rgba(white, 0.9);
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
  & > div > div {
    padding: 10px;
  }
}

nav {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  width: 375px;
  padding: 55px 0 40px 40px;

  background: rgba(255, 255, 255, 0.95);

  transform: translateX(100%);
  transition: transform 0.4s ease-out;

  @media screen and(max-width: 480px) {
    width: 100vw;
    padding-top: 75px;
  }

  .logo-bangsarsouth {
    margin-bottom: 40px;
    padding-right: 40px;

    @include breakpoint(max, sm) {
      margin-bottom: 5%;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 5px;
  }

  li {
    padding: 7px 0px;
    display: flex;
    white-space: nowrap;
    transform: translateX(130%);
    overflow: hidden;

    @include breakpoint(max, sm) {
      padding: 2% 0;
    }

    span {
      height: 30px;
      width: 250px;
      flex-shrink: 0;
      max-width: initial;
      display: inline-flex;
      border-bottom: 2px solid currentColor;
      margin: 0px 5px;
      transform: translateX(100%);
      transition: all 0.25s $easeInQuad;
    }

    a:hover {
      & + span {
        transform: translateX(0%) !important;
      }
    }

    &:nth-child(1) {
      color: #ffd600;
    }
    &:nth-child(2) {
      color: #afcf35;
    }
    &:nth-child(3) {
      color: #00aaeb;
    }
    &:nth-child(4) {
      color: #e91c24;
    }
    &:nth-child(5) {
      color: #f38f1e;
    }
    &:nth-child(6) {
      color: #912468;
    }
  }

  a {
    right: -350px;

    text-decoration: none;
    color: black;

    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      to right,
      currentColor,
      currentColor 50%,
      #000 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    transition: all 0.3s $easeOutQuart;

    // fallback to fading on IE
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      -webkit-text-fill-color: none !important;
      background-clip: none !important;
      background-image: none !important;
    }
  }
  a:hover {
    text-decoration: none;
    color: currentColor;
    background-position: 0%;
    transition: all 0.275s 0.2s $easeOutQuart;
  }
  .nav-icons {
    height: 50px;
    @include breakpoint(max, md) {
      height: 40px;
    }
  }
}
nav.is-active {
  transform: translateX(0);
  // li {
  // &:nth-child(1) {
  // transform: translateX(0%) !important; // transition: all 0.4s $easeOutQuart; // transition-delay: 0.05s; // } // &:nth-child(2) { // transform: translateX(0%); // transition: all 0.4s $easeOutQuart; // transition-delay: 0.1s; // } // &:nth-child(3) { // transform: translateX(0%); // transition: all 0.4s $easeOutQuart; // transition-delay: 0.15s; // } // &:nth-child(4) { // transform: translateX(0%); // transition: all 0.4s $easeOutQuart; // transition-delay: 0.2s; // } // &:nth-child(5) { // transform: translateX(0%); // transition: all 0.4s $easeOutQuart; // transition-delay: 0.25s; // } // }
}
