//Getting here Page
.section--search-menu {
  position: absolute;
  top: 0;

  .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px;
  }

  .container-header-search {
    overflow: hidden;

    .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px;
    }
  }

  .header-search {
    overflow: hidden;
    padding-top: 125px;
    display: flex;
    span {
      color: white;
      padding-left: 72px;
    }

    .header-underline-right {
      width: 125px;
    }
  }
}

.section--search-content {
  padding-top: 200px;
  padding-left: 245px;
  padding-right: 50px;
  min-height: 80vh;

  @include breakpoint(max, md) {
    margin-top: 100px;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .search-post-readmore {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block;
  }

  .search-result:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
