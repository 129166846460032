.section--community-menu {
  position: absolute;
  top: 0;

  .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px;
  }

  .container-header-community {
    overflow: hidden;

    .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px;

      @media screen and (max-width: 830px) {
        display: none;
      }
    }
  }

  .header-community {
    display: flex;
    overflow: hidden;
    padding-top: 125px;
    span {
      color: white;
      padding-left: 25px;
    }

    .header-underline-right {
      width: 125px;
      @include breakpoint(max, md) {
        height: 30px;
      }
    }
  }

  .line-texture {
    background-image: url("../img/line-texture.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 30%;
    margin-left: 10%;
  }
}

.section--community {
  margin-top: 250px;
  // .img-highlight {
  //   background-clip: content-box !important;
  //   background-size: cover !important;
  //   background-position: center !important;
  // }
  .content-detail-container {
    background: white;
    border-top: 6px solid black;
    // background: #e0e1e3;
    padding-top: 35px;
    position: relative;
    @include breakpoint(max, md) {
      padding-bottom: 35px;
    }
    a{
      color: black;
      text-decoration: none;
    }
    .readmore-link {
      border: 2px solid black;
      letter-spacing: 2px;
      font-size: 12px;
      font-weight: 600;
      padding: 7px;
      color: black;
      text-decoration: none;
      display: inline-block;
    }
    h3{
      width: 100% !important;
      word-break: break-word;
    }
  }
  .highlight-tab-container {
    display: flex;
    justify-content: center;
    margin-right: 8.33333333%;
    padding-top: 10px;
    cursor: pointer;
    .highlight-tab {
      display: block;
      border: 1px solid black;
      height: 6px;
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      max-width: 80px;

      &.active {
        background: $yellow;
        border: 1px solid $yellow;
      }
    }
  }

  .article-container {
    justify-content: space-evenly;
    .article {
      max-width: 350px;
      min-width: 350px;
      overflow: hidden;
      margin: 0% 1%;
      img {
        align-self: center;
        height: 400px;
      }
      .article-title {
        font-weight: 600;
        text-align: center;
        padding: 6px 0px;
        margin-bottom: 7px;
        border-bottom: 1px solid gray;
        text-transform: uppercase;
      }
      .article-date {
        text-align: center;
        color: gray;
      }
      .article-img {
        height: 350px;
        width: 350px;
        background-size: cover !important;
        background-position: center !important;
      }
      a{
        color: black;
        text-decoration: none;
      }
    }
  }

  .highlight-slide {
    @include breakpoint(min, md) {
      // min-height: 450px;
    }
    @include breakpoint(max, lg) {
      min-height: 550px;
      flex-direction: column;
      .img-highlight {
        // min-height: 300px;
        height: auto;
        width: 100%;
      }
    }
    .img-highlight {
      height: auto;
      width: 100%;
      // background-size: cover !important;
      // background-position: center !important;
    }
  }
}

.filters-container {
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 830px) {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translatex(-50%);
  }
  a {
    color: black;
    text-decoration: none;
  }
}

.section--community-post {
  .featured-image {
    display: none !important;
  }
  iframe {
    width: 100%;
  }
}

.year.nav:link { font-weight: 500; }
.year.active:link { font-weight: 800; }