.section--directory-menu {
  position: absolute;
  z-index: 1;
  top: 0;

  width: 100%;

  .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
  }

  .container-header-directory {
    overflow: hidden;
  }

  .header-directory {
    overflow: hidden;
    padding-top: 125px;
    @include breakpoint(max, sm){
      width: unset !important;
    }
    span {
      color: white;
      padding-left: 30px;
      @include breakpoint(max, md){
        padding-left: 26px;
      }
    }

    .header-underline-right {
      width: 125px;
      @include breakpoint(max, md) {
        height: 30px;
      }
    }
  }

  .subpage-directory {
    padding-top: 225px;
    padding-bottom: 10px;
    padding-left: 0;
    line-height: 18px;

    p {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      color: white;
      font-weight: 400;
      letter-spacing: 2px;
    }

    .header-underline-left {
      height: 32px;
      border-bottom: 1px solid white;
      max-width: 200px;
      transform-origin: left;
      width: unset;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }

  .header-detail-text {
    font-size: 12px;
    font-weight: 600;
    margin-left: 220px;
    position: relative;
    top: -15px;

    @include breakpoint(max, sm) {
      display: none;
    }
  }
}

.section--directory {
  margin-top: 250px;
  .logos-container {
    // width: calc(100% + 15px);
    margin-bottom: 25px;
  }
  select {
    background: white;
    border: 1px solid lightgray;
    border-radius: 3px;
    width: 250px;
    height: 40px;
    margin-bottom: 15px;
    text-align-last: center;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    -webkit-appearance: none;
    -moz-appearance: none;

    @include breakpoint(max, sm) {
      width: 90%;
    }

    /* new style */
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }
  #submitBtn {
    margin-left: 15px;
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    position: relative;
    top: -2px;
    color: black;
    text-decoration: none;
  }
  .directory-item {
    &.is-active {
      .item--logo {
        z-index: 5;
        position: relative;
        -webkit-box-shadow: 0px 0px 30px -5px rgba(125, 125, 125, 1);
        -moz-box-shadow: 0px 0px 30px -5px rgba(125, 125, 125, 1);
        box-shadow: 0px 0px 30px -5px rgba(125, 125, 125, 1);
      }
    }
    .item--wrapper {
      .company-name {
        text-align: center;
        font-weight: 600;
        color: #818181;
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 11px;
        text-transform: uppercase;
        height: 32px;
      }
    }
    .item--logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      height: 170px;
      padding: 15px 15px 0px 15px;
      border: 1px solid hsl(0, 0%, 90%);

      margin-top: -1px;
      margin-right: -1px;

      img {
        height: 60%;
        transform: scale(1);
        transition: all 0.5s ease-in-out;
      }
      img:hover {
        transform: scale(1.1);
        transition: all 0.5s ease-in-out;
      }
    }

    .item--content {
      position: relative;

      overflow: hidden;
      width: calc(400% + 1px);

      background: hsl(0, 0%, 95%);

      .content--inner {
        display: flex;
        padding: 15px 40px;
      }

      .company-details {
        display: flex;
        flex-direction: column;
        padding: 10px;
        & > p:nth-child(1) {
          font-weight: 600;
        }
      }

      .mini-map {
        img {
          width: 100%;
        }
      }
    }
  }

  .directory-item {
    background: white;
    @for $i from 1 through 4 {
      &:nth-child(4n + #{$i}) .item--content {
        left: #{-100% * ($i - 1)};
      }
    }
  }

  @media all and(max-width: 992px) {
    .directory-item {
      .item--content {
        width: calc(200% + 1px);
      }

      @for $i from 1 through 2 {
        &:nth-child(2n + #{$i}) .item--content {
          left: -100% * ($i - 1);
        }
      }
    }
  }

  @media all and(max-width: 576px) {
    .directory-item {
      .item--content {
        width: 100%;
        left: 0 !important;
      }
      .content--inner {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
