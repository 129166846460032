//Getting here Page
.section--getting-here-menu {
  position: absolute;
  top: 0;

  .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px;
    @media screen and (max-width: 375px){
      height: 435px;
    }
  }

  .container-header-getting-here {
    overflow: hidden;

    .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px;
    }
  }

  .header-getting-here {
    overflow: hidden;
    padding-top: 125px;
    display: flex;
    span {
      color: white;
      padding-left: 98px;
      @media screen and (max-width: 480px){
        padding-left: 10px;
      }
    }

    .header-underline-right {
      width: 125px;
      @include breakpoint(max,md){
        height: 30px;
      }
    }
  }

  .line-texture {
    background-image: url("../img/line-texture.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 30%;
    margin-left: 10%;
  }
}

.section--gettinghere {
  margin-top: 250px;
  .column-img-container {
    max-height: 550px;
    overflow: hidden;
  }
  .line-texture {
    position: absolute;
    z-index: -1;
    right: 0;
    width: 35%;
    transform: translate(50%, -90%);
  }
  .box--green {
    position: absolute;
    background: #9ebf35;
    z-index: -1;
    transform: translate(345%, -50%);
    width: 25%;
    height: 70%;
    img {
      width: 100%;
      height: 100%;
      opacity: 0.2;
    }
  }
  .header--shuttleservice {
    padding-top: 1.5%;
    h1 {
      font-size: 3rem;
      letter-spacing: 5px;
    }
  }
  .textbox--shuttleservice {
    width: 300px;
  }

  @media screen and(max-width: 1200px) {
    .map-media-flex {
      max-width: 100%;
      flex: unset;
    }
  }

  .directions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      color: black;
      text-decoration: none;
    }
    .waze-container {
      display: flex;
      align-items: center;
      margin-right: 25px;
      margin-bottom: 20px;
      p {
        margin: 0;
      }
      img {
        height: 25px;
        margin-left: 10px;
      }
    }
    .gmaps-container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        margin: 0;
      }
      img {
        height: 25px;
        margin-left: 10px;
      }
    }
  }

  .icon-container {
    padding: 0px 40px;
    img {
      width: 40px;
      margin-bottom: 10px;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }

  #interactive-map{
    @include breakpoint(max, sm){
      margin: 0 !important;
    }
  }
}
