// media breakpoint mixin
@mixin breakpoint($m, $breakpoint) {
  $breakpoints: (
    'sm': $breakpoint-sm,
    'md': $breakpoint-md,
    'lg': $breakpoint-lg,
    'lg-2': $breakpoint-1024,
    'xl': $breakpoint-xl
  );

  @media all and (#{$m}-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}