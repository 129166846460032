//Landing Page

.gold-theme {
  color: #cc9933 !important;
  border: 1px solid #cc9933 !important;
}

// a.grayBorder {
//   border: 1px solid #606060 !important;
// }

.section--splash {
  height: 101vh;
  max-width: 100vw;
  overflow: hidden;
  color: white;

  //  @media only screen and (min-width: 414px) and (max-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  //   .splash-slide[data-content='appreciation-frontliners'] {
  //     background-image: url('../img/landing-page/appreciation-frontliners-mobile-iphone-11.jpg') !important;
  //   }
  // }
  .splash-slide[data-content="cny_2025"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/cny-2025.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/cny-2025-m.png") !important;
    }
  }

  .splash-slide[data-content="christmas_cny_2024"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/christmas_cny_2024.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/christmas_cny_m_2024.png") !important;
    }
  }

  .splash-slide[data-content="independence-day-2024"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/independence-day-2024.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/independence-day-2024-m.jpg") !important;
    }
  }

  .splash-slide[data-content="cny-2024"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/cny-2024.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/cny-2024-m.jpg") !important;
    }
  }
  .splash-slide[data-content="raya-2024"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/raya-2024-desktop.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/raya-2024-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="national-day-2023"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/bg-national-day-2023.png") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/bg-national-day-2023-m.jpg") !important;
    }
  }

  .splash-slide[data-content="independence-day-2022"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/independence-day-2022/bg-independence-2022-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/independence-day-2022/bg-independence-2022-tablet.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/independence-day-2022/bg-independence-2022-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="raya-2022"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/raya-2022/raya-banner-2022.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/raya-2022/raya-banner-2022-tablet.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/raya-2022/raya-banner-2022-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="cny-2022"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/cny-2022/bg-cny-desktop.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/cny-2022/bg-cny-tablet.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/cny-2022/bg-cny-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="christmas-2021"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/christmas-2021/bg-christmas-2021-mobile-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/christmas-2021/bg-christmas-2021-tablet-landscape.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/christmas-2021/bg-christmas-2021-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="deepavali-2020"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/deepavali-2020/bg-deepavali-2020-mobile-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/deepavali-2020/bg-deepavali-2020-tablet-landscape.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/deepavali-2020/bg-deepavali-2020-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="raya-2020"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/raya-2020-mobile-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/raya-2020-tablet-poitrait.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/raya-2020-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="deepavali-2021"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/deepavali-2021/bg-deepavali-2021-mobile-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/deepavali-2021/bg-deepavali-2021-tablet-landscape.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/deepavali-2021/bg-deepavali-2021-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="deepavali-2022"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/deepavali-2022/bg-deepavali-2022-mobile-iphone-11.png") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/deepavali-2022/bg-deepavali-2022-tablet-landscape.png") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/deepavali-2022/bg-deepavali-2022-mobile.png") !important;
    }
  }

  .splash-slide[data-content="christmas-2022"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/christmas-2022/bg-christmas-2022-mobile-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/christmas-2022/bg-christmas-2022-tablet-landscape.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/christmas-2022/bg-christmas-2022-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="cny-2023"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/cny-2023/bg-cny-mobile-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/cny-2023/bg-cny-tablet-landscape.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/cny-2023/bg-cny-mobile.jpg") !important;
    }
  }

  .splash-slide[data-content="raya-2023"] {
    @include breakpoint(max, lg-2) {
      background-image: url("../img/landing-page/raya-2023/bg-raya-mobile-iphone-11.jpg") !important;
    }
    @include breakpoint(max, md) {
      background-image: url("../img/landing-page/raya-2023/bg-raya-tablet-landscape.jpg") !important;
    }
    @include breakpoint(max, sm) {
      background-image: url("../img/landing-page/raya-2023/bg-raya-mobile.jpg") !important;
    }
  }

  .hideFestiveBtn {
    opacity: 1;
  }

  // Change opacity=0 when add banner event
  //Comment all when want to hide button but display title = JS
  .hideSplashContent {
    // @include breakpoint(min, sm) {
    opacity: 0;
    // }
    @include breakpoint(max, sm) {
      bottom: 0 !important;
    }
    .container-fluid {
      padding-left: 0;
    }
  }

  // Change opacity=0 when add banner event
  //Comment all when want to hide button but display title = JS
  .noTransitions * {
    // @include breakpoint(max, lg-2) {
    opacity: 0;
    // }
  }

  //Speciall case to add event with button
  .hide-title {
    opacity: 0;
  }

  .splash-bg {
    &,
    & > div,
    & > div > div,
    .splash-slide {
      height: 100%;
      overflow: hidden;
    }
  }

  .splash-content {
    position: absolute;
    width: 100%;
    bottom: 50px;
    left: 00px;
    padding: 15px 50px;
    overflow: hidden;

    @include breakpoint(max, md) {
      padding: 15px;
    }

    @include breakpoint(max, sm) {
      bottom: 0 !important;
    }

    .content-row,
    .discoverMoreBtn {
      transition: all 0.5s ease-out;
      display: table;
      // opacity: 0;
    }
  }

  .splash-slide {
    background-size: cover;
    background-position: center;
    transition: all 6s ease-out;
  }

  .is-current {
    transform: scale(1.05);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform: scale(1.05) rotate(0.01deg);
    }
  }

  .is-previous {
    transform: translateX(60%);
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  h1 {
    color: white;
    font-size: 120px;
    line-height: 100px;
    font-family: "Montserrat";
    font-weight: 700;

    @include breakpoint(max, lg) {
      font-size: 120px;
    }

    @include breakpoint(max, md) {
      font-size: 80px;
    }

    @include breakpoint(max, sm) {
      font-size: 55px;
      line-height: 50px;
    }

    @media screen and (max-width: 350px) {
      font-size: 45px;
    }
  }

  p {
    font-family: "Gotham";
    color: white;
    letter-spacing: 1.1px;
  }

  a {
    border: 1px solid white;
    letter-spacing: 3px;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    color: white;
    text-decoration: none;
  }
}

.section--landing-community {
  position: relative;
  padding-bottom: 50px;
  max-width: 100vw;
  overflow: hidden;

  .community-header {
    @include breakpoint(max, sm) {
      display: flex;
      justify-content: center;
    }
    h1 {
      font-size: 3rem;
      letter-spacing: 5px;
      float: right;
      padding-top: 25px;

      @include breakpoint(max, sm) {
        position: relative;
        font-size: 2.5rem;
      }
    }
  }

  .yellow-box {
    position: absolute;
    top: 0;
    left: 0;

    width: 40%;
    height: 750px;
    overflow: hidden;
    background: #ffc600;

    img {
      height: 80%;
    }
  }

  .texture-box {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 400px;
    padding: 0 15px;

    overflow: hidden;
    background-color: white;
    background-clip: content-box;

    @include breakpoint(max, sm) {
      height: unset;
    }

    img {
      width: 100%;
      height: auto;
      opacity: 0.3;
    }
  }

  .container-carousel {
    padding: 0 50px;
    margin-bottom: 20px;
  }

  .carousel-navigation {
    img {
      width: 35px;
    }

    .left-nav-btn {
      left: 25px;
      position: absolute;
      top: 50%;
    }

    .right-nav-btn {
      right: 25px;
      position: absolute;
      top: 50%;
    }
  }
}

.section--landing-gettinghere {
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  .container {
    @include breakpoint(max, md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .row {
    position: relative;
    flex-wrap: nowrap;
  }

  .header--gettinghere {
    left: 0;
    overflow: hidden;

    h1 {
      font-size: 3rem;
      letter-spacing: 5px;
      position: relative;
      padding-top: 25px;
      padding-left: 10%;

      @include breakpoint(max, sm) {
        padding-left: 0;
      }
    }
  }

  .box--green {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;

    width: calc(50% - 15px);
    height: 100%;
    padding: 15px;

    background: #9ebf35;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75%;
      background-image: url("../img/line-texture-green.jpg");
      opacity: 0.8;
    }

    p {
      max-width: 310px;
    }

    @include breakpoint(max, sm) {
      position: static;
      width: 100%;

      padding: 45px 15px;

      &:before {
        height: 100%;
      }
    }
  }

  img {
    width: 100%;
  }

  a {
    border: 1px solid white;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
    color: white;
    text-decoration: none;
    display: inline-block;
  }
}
