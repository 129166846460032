/* ********************************************
 * Easing Variables
 * *************************************/
@font-face {
  font-family: 'Gotham';
  src: url("./../fonts/Gotham-Book.woff2") format("woff2"), url("./../fonts/Gotham-Book.woff") format("woff"), url("./../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Regular.woff2") format("woff2"), url("./../fonts/Montserrat-Regular.woff") format("woff"), url("./../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("./../fonts/Montserrat-SemiBold.woff") format("woff"), url("./../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Bold.woff2") format("woff2"), url("./../fonts/Montserrat-Bold.woff") format("woff"), url("./../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

.align-center {
  text-align: center; }

.font-gotham {
  font-family: 'Gotham'; }

.font-bold {
  font-weight: 700; }

body {
  overflow-x: hidden;
  font-family: 'Montserrat'; }
  body h1 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 5px; }
    @media all and (max-width: 768px) {
      body h1 {
        font-size: 2rem; } }
  body p {
    font-size: 14px;
    font-weight: 400; }

.header-underline-left {
  float: left;
  border-bottom: 2px solid black;
  margin-right: 10px;
  width: 125px;
  height: 45px;
  flex: 1 1 auto;
  max-width: 125px; }
  @media all and (max-width: 576px) {
    .header-underline-left {
      display: none; } }

.header-underline-right {
  display: inline-flex;
  border-bottom: 2px solid black;
  margin-left: 10px;
  flex: 1 1 auto;
  max-width: 125px;
  height: 45px; }

.section--header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 15px 0; }
  .section--header span {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-bottom: 10px;
    border-bottom: 2px solid black; }

.social-icon-container img {
  height: 40px; }

.icons-container {
  margin-top: 50px; }
  .icons-container img {
    width: 150px;
    margin: 15px; }

.yellow-info-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  margin: 0 auto;
  background-color: #fcb02a;
  border-radius: 50%;
  text-align: center; }
  .yellow-info-circle h1 {
    margin: 0;
    letter-spacing: 0;
    font-size: 2rem; }
  .yellow-info-circle p {
    font-weight: 600;
    line-height: 14px;
    width: 95%;
    margin: 0; }
  .yellow-info-circle img {
    padding-top: 5px;
    width: auto;
    height: 50px; }

button {
  background: white; }

select::-ms-expand {
  display: none; }

#menu-search-input {
  border-radius: 3px;
  border: 1px solid #ced4da;
  width: 0;
  transition: all 400ms ease-out;
  opacity: 0;
  transform-origin: right; }
  #menu-search-input.is-active {
    opacity: 1 !important;
    width: 220px !important;
    margin-left: 8px; }
    @media screen and (max-width: 365px) {
      #menu-search-input.is-active {
        width: 175px !important; } }

#menu-search-btn {
  max-width: 100%;
  height: auto; }

header {
  top: 0;
  z-index: 99;
  pointer-events: none;
  padding-right: 0 !important; }
  header .nav-toggle {
    pointer-events: all;
    cursor: pointer; }
  header > div:nth-child(1) {
    padding-right: 10px; }
  header > div:nth-child(1).is-active {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1); }
  header > div > div {
    padding: 10px; }

nav {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 375px;
  padding: 55px 0 40px 40px;
  background: rgba(255, 255, 255, 0.95);
  transform: translateX(100%);
  transition: transform 0.4s ease-out; }
  @media screen and (max-width: 480px) {
    nav {
      width: 100vw;
      padding-top: 75px; } }
  nav .logo-bangsarsouth {
    margin-bottom: 40px;
    padding-right: 40px; }
    @media all and (max-width: 576px) {
      nav .logo-bangsarsouth {
        margin-bottom: 5%; } }
  nav ul {
    list-style: none;
    padding: 0;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 5px; }
  nav li {
    padding: 7px 0px;
    display: flex;
    white-space: nowrap;
    transform: translateX(130%);
    overflow: hidden; }
    @media all and (max-width: 576px) {
      nav li {
        padding: 2% 0; } }
    nav li span {
      height: 30px;
      width: 250px;
      flex-shrink: 0;
      max-width: initial;
      display: inline-flex;
      border-bottom: 2px solid currentColor;
      margin: 0px 5px;
      transform: translateX(100%);
      transition: all 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53); }
    nav li a:hover + span {
      transform: translateX(0%) !important; }
    nav li:nth-child(1) {
      color: #ffd600; }
    nav li:nth-child(2) {
      color: #afcf35; }
    nav li:nth-child(3) {
      color: #00aaeb; }
    nav li:nth-child(4) {
      color: #e91c24; }
    nav li:nth-child(5) {
      color: #f38f1e; }
    nav li:nth-child(6) {
      color: #912468; }
  nav a {
    right: -350px;
    text-decoration: none;
    color: black;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, currentColor, currentColor 50%, #000 50%);
    background-size: 200% 100%;
    background-position: -100%;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      nav a {
        -webkit-text-fill-color: none !important;
        background-clip: none !important;
        background-image: none !important; } }
  nav a:hover {
    text-decoration: none;
    color: currentColor;
    background-position: 0%;
    transition: all 0.275s 0.2s cubic-bezier(0.165, 0.84, 0.44, 1); }
  nav .nav-icons {
    height: 50px; }
    @media all and (max-width: 768px) {
      nav .nav-icons {
        height: 40px; } }

nav.is-active {
  transform: translateX(0); }

footer {
  position: relative;
  padding: 40px 15px;
  margin-top: 40px; }
  @media all and (max-width: 768px) {
    footer {
      padding: 20px 15px; } }
  footer:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./../img/gray-texture.jpg");
    background-size: cover;
    background-position: center bottom;
    opacity: 0.3; }
  footer p.fine {
    margin: 0;
    margin-bottom: 5px;
    font-size: 0.7rem;
    text-align: center; }
    footer p.fine a {
      color: black !important; }

.footer--content {
  text-align: center;
  border-left: 1px solid black;
  border-right: 1px solid black; }
  @media all and (max-width: 992px) {
    .footer--content {
      border: none; } }
  .footer--content p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 0.85rem; }
  .footer--content .icons span {
    cursor: pointer; }
  .footer--content .icons img {
    width: 50px; }

.community-carousel p {
  width: 100% !important;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 225px; }

.community-carousel a {
  float: left;
  border: 1px solid black;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat";
  padding: 8px;
  color: black;
  text-decoration: none; }

.community-carousel > div > div {
  transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
  padding-bottom: 50px;
  opacity: 0; }

.community-carousel.is-up > div > div:nth-child(2n + 1) {
  padding-top: 50px;
  padding-bottom: 0; }

.community-carousel.is-down > div > div:nth-child(2n + 2) {
  padding-top: 50px;
  padding-bottom: 0; }

.community-carousel.is-opaque > div > div {
  opacity: 1; }

.community-carousel .slide-img-container {
  position: relative;
  height: 350px;
  overflow: hidden;
  display: flex;
  justify-content: center; }
  @media all and (max-width: 576px) {
    .community-carousel .slide-img-container {
      height: 67vw; } }
  .community-carousel .slide-img-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: auto;
    transform: translate(-50%, -50%); }

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .slide-img-container {
    width: 23vw !important;
    height: 23vw !important;
    padding: 0px 10px; } }

.map-container,
.map-container2 {
  background: #e0e0e0;
  overflow: hidden;
  padding: 10px;
  height: 100%; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .map-container,
    .map-container2 {
      min-height: 500px; } }
  .map-container img,
  .map-container2 img {
    width: 100%; }
  .map-container:focus,
  .map-container2:focus {
    outline: none; }

#map-container-inner,
#map-container-inner2 {
  height: 100%; }

.zoom-btn-container,
.zoom-btn-container2 {
  position: absolute;
  top: 0;
  right: 30px;
  padding-top: 15px;
  display: flex;
  flex-direction: column; }
  .zoom-btn-container img,
  .zoom-btn-container2 img {
    width: 30px;
    padding-top: 5px; }

.routes-container,
.routes-container2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: none; }
  .routes-container .dashed,
  .routes-container2 .dashed {
    stroke-dasharray: 5; }
  .routes-container .path,
  .routes-container2 .path {
    stroke-dasharray: 2000;
    stroke-dashoffset: 0; }
  .routes-container svg,
  .routes-container2 svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .routes-container.active, .routes-container.labels-only,
  .routes-container2.active,
  .routes-container2.labels-only {
    display: block; }

.route-4 svg:first-child path {
  opacity: 0; }

.route-btn {
  display: block;
  border: 2px solid black;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  padding: 3px;
  color: black;
  text-decoration: none;
  text-align: center;
  width: 100%;
  user-select: none;
  cursor: pointer;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1); }
  .route-btn.active {
    background: black !important;
    color: white !important; }

.gold-theme {
  color: #cc9933 !important;
  border: 1px solid #cc9933 !important; }

.section--splash {
  height: 101vh;
  max-width: 100vw;
  overflow: hidden;
  color: white; }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="cny_2025"] {
      background-image: url("../img/landing-page/cny-2025.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="cny_2025"] {
      background-image: url("../img/landing-page/cny-2025-m.png") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="christmas_cny_2024"] {
      background-image: url("../img/landing-page/christmas_cny_2024.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="christmas_cny_2024"] {
      background-image: url("../img/landing-page/christmas_cny_m_2024.png") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="independence-day-2024"] {
      background-image: url("../img/landing-page/independence-day-2024.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="independence-day-2024"] {
      background-image: url("../img/landing-page/independence-day-2024-m.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="cny-2024"] {
      background-image: url("../img/landing-page/cny-2024.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="cny-2024"] {
      background-image: url("../img/landing-page/cny-2024-m.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="raya-2024"] {
      background-image: url("../img/landing-page/raya-2024-desktop.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="raya-2024"] {
      background-image: url("../img/landing-page/raya-2024-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="national-day-2023"] {
      background-image: url("../img/landing-page/bg-national-day-2023.png") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="national-day-2023"] {
      background-image: url("../img/landing-page/bg-national-day-2023-m.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="independence-day-2022"] {
      background-image: url("../img/landing-page/independence-day-2022/bg-independence-2022-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="independence-day-2022"] {
      background-image: url("../img/landing-page/independence-day-2022/bg-independence-2022-tablet.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="independence-day-2022"] {
      background-image: url("../img/landing-page/independence-day-2022/bg-independence-2022-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="raya-2022"] {
      background-image: url("../img/landing-page/raya-2022/raya-banner-2022.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="raya-2022"] {
      background-image: url("../img/landing-page/raya-2022/raya-banner-2022-tablet.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="raya-2022"] {
      background-image: url("../img/landing-page/raya-2022/raya-banner-2022-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="cny-2022"] {
      background-image: url("../img/landing-page/cny-2022/bg-cny-desktop.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="cny-2022"] {
      background-image: url("../img/landing-page/cny-2022/bg-cny-tablet.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="cny-2022"] {
      background-image: url("../img/landing-page/cny-2022/bg-cny-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="christmas-2021"] {
      background-image: url("../img/landing-page/christmas-2021/bg-christmas-2021-mobile-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="christmas-2021"] {
      background-image: url("../img/landing-page/christmas-2021/bg-christmas-2021-tablet-landscape.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="christmas-2021"] {
      background-image: url("../img/landing-page/christmas-2021/bg-christmas-2021-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="deepavali-2020"] {
      background-image: url("../img/landing-page/deepavali-2020/bg-deepavali-2020-mobile-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="deepavali-2020"] {
      background-image: url("../img/landing-page/deepavali-2020/bg-deepavali-2020-tablet-landscape.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="deepavali-2020"] {
      background-image: url("../img/landing-page/deepavali-2020/bg-deepavali-2020-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="raya-2020"] {
      background-image: url("../img/landing-page/raya-2020-mobile-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="raya-2020"] {
      background-image: url("../img/landing-page/raya-2020-tablet-poitrait.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="raya-2020"] {
      background-image: url("../img/landing-page/raya-2020-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="deepavali-2021"] {
      background-image: url("../img/landing-page/deepavali-2021/bg-deepavali-2021-mobile-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="deepavali-2021"] {
      background-image: url("../img/landing-page/deepavali-2021/bg-deepavali-2021-tablet-landscape.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="deepavali-2021"] {
      background-image: url("../img/landing-page/deepavali-2021/bg-deepavali-2021-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="deepavali-2022"] {
      background-image: url("../img/landing-page/deepavali-2022/bg-deepavali-2022-mobile-iphone-11.png") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="deepavali-2022"] {
      background-image: url("../img/landing-page/deepavali-2022/bg-deepavali-2022-tablet-landscape.png") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="deepavali-2022"] {
      background-image: url("../img/landing-page/deepavali-2022/bg-deepavali-2022-mobile.png") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="christmas-2022"] {
      background-image: url("../img/landing-page/christmas-2022/bg-christmas-2022-mobile-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="christmas-2022"] {
      background-image: url("../img/landing-page/christmas-2022/bg-christmas-2022-tablet-landscape.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="christmas-2022"] {
      background-image: url("../img/landing-page/christmas-2022/bg-christmas-2022-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="cny-2023"] {
      background-image: url("../img/landing-page/cny-2023/bg-cny-mobile-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="cny-2023"] {
      background-image: url("../img/landing-page/cny-2023/bg-cny-tablet-landscape.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="cny-2023"] {
      background-image: url("../img/landing-page/cny-2023/bg-cny-mobile.jpg") !important; } }
  @media all and (max-width: 1024px) {
    .section--splash .splash-slide[data-content="raya-2023"] {
      background-image: url("../img/landing-page/raya-2023/bg-raya-mobile-iphone-11.jpg") !important; } }
  @media all and (max-width: 768px) {
    .section--splash .splash-slide[data-content="raya-2023"] {
      background-image: url("../img/landing-page/raya-2023/bg-raya-tablet-landscape.jpg") !important; } }
  @media all and (max-width: 576px) {
    .section--splash .splash-slide[data-content="raya-2023"] {
      background-image: url("../img/landing-page/raya-2023/bg-raya-mobile.jpg") !important; } }
  .section--splash .hideFestiveBtn {
    opacity: 1; }
  .section--splash .hideSplashContent {
    opacity: 0; }
    @media all and (max-width: 576px) {
      .section--splash .hideSplashContent {
        bottom: 0 !important; } }
    .section--splash .hideSplashContent .container-fluid {
      padding-left: 0; }
  .section--splash .noTransitions * {
    opacity: 0; }
  .section--splash .hide-title {
    opacity: 0; }
  .section--splash .splash-bg,
  .section--splash .splash-bg > div,
  .section--splash .splash-bg > div > div,
  .section--splash .splash-bg .splash-slide {
    height: 100%;
    overflow: hidden; }
  .section--splash .splash-content {
    position: absolute;
    width: 100%;
    bottom: 50px;
    left: 00px;
    padding: 15px 50px;
    overflow: hidden; }
    @media all and (max-width: 768px) {
      .section--splash .splash-content {
        padding: 15px; } }
    @media all and (max-width: 576px) {
      .section--splash .splash-content {
        bottom: 0 !important; } }
    .section--splash .splash-content .content-row,
    .section--splash .splash-content .discoverMoreBtn {
      transition: all 0.5s ease-out;
      display: table; }
  .section--splash .splash-slide {
    background-size: cover;
    background-position: center;
    transition: all 6s ease-out; }
  .section--splash .is-current {
    transform: scale(1.05); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .section--splash .is-current {
        transform: scale(1.05) rotate(0.01deg); } }
  .section--splash .is-previous {
    transform: translateX(60%);
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .section--splash h1 {
    color: white;
    font-size: 120px;
    line-height: 100px;
    font-family: "Montserrat";
    font-weight: 700; }
    @media all and (max-width: 992px) {
      .section--splash h1 {
        font-size: 120px; } }
    @media all and (max-width: 768px) {
      .section--splash h1 {
        font-size: 80px; } }
    @media all and (max-width: 576px) {
      .section--splash h1 {
        font-size: 55px;
        line-height: 50px; } }
    @media screen and (max-width: 350px) {
      .section--splash h1 {
        font-size: 45px; } }
  .section--splash p {
    font-family: "Gotham";
    color: white;
    letter-spacing: 1.1px; }
  .section--splash a {
    border: 1px solid white;
    letter-spacing: 3px;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    color: white;
    text-decoration: none; }

.section--landing-community {
  position: relative;
  padding-bottom: 50px;
  max-width: 100vw;
  overflow: hidden; }
  @media all and (max-width: 576px) {
    .section--landing-community .community-header {
      display: flex;
      justify-content: center; } }
  .section--landing-community .community-header h1 {
    font-size: 3rem;
    letter-spacing: 5px;
    float: right;
    padding-top: 25px; }
    @media all and (max-width: 576px) {
      .section--landing-community .community-header h1 {
        position: relative;
        font-size: 2.5rem; } }
  .section--landing-community .yellow-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 750px;
    overflow: hidden;
    background: #ffc600; }
    .section--landing-community .yellow-box img {
      height: 80%; }
  .section--landing-community .texture-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 400px;
    padding: 0 15px;
    overflow: hidden;
    background-color: white;
    background-clip: content-box; }
    @media all and (max-width: 576px) {
      .section--landing-community .texture-box {
        height: unset; } }
    .section--landing-community .texture-box img {
      width: 100%;
      height: auto;
      opacity: 0.3; }
  .section--landing-community .container-carousel {
    padding: 0 50px;
    margin-bottom: 20px; }
  .section--landing-community .carousel-navigation img {
    width: 35px; }
  .section--landing-community .carousel-navigation .left-nav-btn {
    left: 25px;
    position: absolute;
    top: 50%; }
  .section--landing-community .carousel-navigation .right-nav-btn {
    right: 25px;
    position: absolute;
    top: 50%; }

.section--landing-gettinghere {
  position: relative;
  max-width: 100vw;
  overflow: hidden; }
  @media all and (max-width: 768px) {
    .section--landing-gettinghere .container {
      padding-left: 0;
      padding-right: 0; } }
  .section--landing-gettinghere .row {
    position: relative;
    flex-wrap: nowrap; }
  .section--landing-gettinghere .header--gettinghere {
    left: 0;
    overflow: hidden; }
    .section--landing-gettinghere .header--gettinghere h1 {
      font-size: 3rem;
      letter-spacing: 5px;
      position: relative;
      padding-top: 25px;
      padding-left: 10%; }
      @media all and (max-width: 576px) {
        .section--landing-gettinghere .header--gettinghere h1 {
          padding-left: 0; } }
  .section--landing-gettinghere .box--green {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - 15px);
    height: 100%;
    padding: 15px;
    background: #9ebf35;
    text-align: center; }
    .section--landing-gettinghere .box--green:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75%;
      background-image: url("../img/line-texture-green.jpg");
      opacity: 0.8; }
    .section--landing-gettinghere .box--green p {
      max-width: 310px; }
    @media all and (max-width: 576px) {
      .section--landing-gettinghere .box--green {
        position: static;
        width: 100%;
        padding: 45px 15px; }
        .section--landing-gettinghere .box--green:before {
          height: 100%; } }
  .section--landing-gettinghere img {
    width: 100%; }
  .section--landing-gettinghere a {
    border: 1px solid white;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
    color: white;
    text-decoration: none;
    display: inline-block; }

.section--getting-here-menu {
  position: absolute;
  top: 0; }
  .section--getting-here-menu .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px; }
    @media screen and (max-width: 375px) {
      .section--getting-here-menu .texture-box {
        height: 435px; } }
  .section--getting-here-menu .container-header-getting-here {
    overflow: hidden; }
    .section--getting-here-menu .container-header-getting-here .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px; }
  .section--getting-here-menu .header-getting-here {
    overflow: hidden;
    padding-top: 125px;
    display: flex; }
    .section--getting-here-menu .header-getting-here span {
      color: white;
      padding-left: 98px; }
      @media screen and (max-width: 480px) {
        .section--getting-here-menu .header-getting-here span {
          padding-left: 10px; } }
    .section--getting-here-menu .header-getting-here .header-underline-right {
      width: 125px; }
      @media all and (max-width: 768px) {
        .section--getting-here-menu .header-getting-here .header-underline-right {
          height: 30px; } }
  .section--getting-here-menu .line-texture {
    background-image: url("../img/line-texture.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 30%;
    margin-left: 10%; }

.section--gettinghere {
  margin-top: 250px; }
  .section--gettinghere .column-img-container {
    max-height: 550px;
    overflow: hidden; }
  .section--gettinghere .line-texture {
    position: absolute;
    z-index: -1;
    right: 0;
    width: 35%;
    transform: translate(50%, -90%); }
  .section--gettinghere .box--green {
    position: absolute;
    background: #9ebf35;
    z-index: -1;
    transform: translate(345%, -50%);
    width: 25%;
    height: 70%; }
    .section--gettinghere .box--green img {
      width: 100%;
      height: 100%;
      opacity: 0.2; }
  .section--gettinghere .header--shuttleservice {
    padding-top: 1.5%; }
    .section--gettinghere .header--shuttleservice h1 {
      font-size: 3rem;
      letter-spacing: 5px; }
  .section--gettinghere .textbox--shuttleservice {
    width: 300px; }
  @media screen and (max-width: 1200px) {
    .section--gettinghere .map-media-flex {
      max-width: 100%;
      flex: unset; } }
  .section--gettinghere .directions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .section--gettinghere .directions-container a {
      color: black;
      text-decoration: none; }
    .section--gettinghere .directions-container .waze-container {
      display: flex;
      align-items: center;
      margin-right: 25px;
      margin-bottom: 20px; }
      .section--gettinghere .directions-container .waze-container p {
        margin: 0; }
      .section--gettinghere .directions-container .waze-container img {
        height: 25px;
        margin-left: 10px; }
    .section--gettinghere .directions-container .gmaps-container {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .section--gettinghere .directions-container .gmaps-container p {
        margin: 0; }
      .section--gettinghere .directions-container .gmaps-container img {
        height: 25px;
        margin-left: 10px; }
  .section--gettinghere .icon-container {
    padding: 0px 40px; }
    .section--gettinghere .icon-container img {
      width: 40px;
      margin-bottom: 10px; }
    .section--gettinghere .icon-container a {
      color: black;
      text-decoration: none; }
  @media all and (max-width: 576px) {
    .section--gettinghere #interactive-map {
      margin: 0 !important; } }

.section--contactus img {
  width: 100%; }

.section--contactus .column-img-container {
  position: relative;
  z-index: 5;
  max-height: 550px;
  overflow: hidden; }

.section--contactus .line-texture {
  position: absolute;
  z-index: -1;
  right: 0;
  width: 35%;
  transform: translate(50%, -90%); }

.section--contactus .box--blue {
  position: absolute;
  background: #009bdc;
  z-index: 4;
  transform: translate(205%, -50%);
  width: 35%;
  height: 65%; }
  .section--contactus .box--blue img {
    width: 100%;
    height: 100%;
    opacity: 0.2; }
  .section--contactus .box--blue .gettingHereBtn {
    border: 2px solid black;
    letter-spacing: 3px;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    color: black;
    text-decoration: none; }

.section--contactus .header--contactus {
  padding-top: 1.5%; }
  .section--contactus .header--contactus h1 {
    font-size: 3rem;
    letter-spacing: 5px; }

.section--contactus .uoa-contacts-container {
  position: absolute;
  top: 112%;
  left: 0; }
  @media all and (max-width: 992px) {
    .section--contactus .uoa-contacts-container {
      display: none; } }

.section--contactus .white-mask {
  background: white;
  position: absolute;
  transform-origin: left;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px; }

.section--contactus .box-white-mask {
  background: white;
  position: absolute;
  transform-origin: left;
  width: 100%;
  height: 101%;
  top: 0;
  left: 0px;
  z-index: 1; }

.section--contactus a {
  color: black;
  text-decoration: none; }

.section--contactus iframe {
  margin-top: 25px;
  margin-bottom: 25px; }

.enquiry-form form .comments-group {
  margin-right: -15px; }

.enquiry-form form .comments-box {
  height: 100px; }

.enquiry-form form label {
  margin: 0;
  display: flex;
  align-items: center; }

.enquiry-form form label,
.enquiry-form form select,
.enquiry-form form textarea,
.enquiry-form form input {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat"; }

@media all and (max-width: 576px) {
  .enquiry-form label {
    margin-left: 5px !important; } }

@media all and (max-width: 576px) {
  .enquiry-form textarea {
    width: 95%; } }

@media all and (max-width: 576px) {
  .enquiry-form select,
  .enquiry-form input {
    margin: 0px 20px; } }

.enquiry-form button {
  border: 2px solid black;
  letter-spacing: 2px;
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 600;
  padding: 7px;
  color: black;
  text-decoration: none; }

@media all and (max-width: 576px) {
  .enquiry-form .form-check {
    padding-left: 0; } }

.grecaptcha-badge {
  bottom: 25px !important; }

.rellax {
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.page-aboutus div[class*="section--"] {
  margin: 10% 0; }

.page-aboutus .section--aboutus-splash {
  margin-top: 0 !important; }

.page-aboutus .section--header {
  margin-right: -100px;
  margin-left: 20px; }

@media all and (max-width: 768px) {
  .page-aboutus [class*="box-"],
  .page-aboutus .line-texture {
    display: none; }
  .page-aboutus .greendesign-bg,
  .page-aboutus .msc-bg,
  .page-aboutus .award-bg {
    width: 100% !important; }
  .page-aboutus .section--header {
    margin-right: 0;
    margin-left: 0; } }

.section--aboutus-splash img {
  width: 100%; }

.section--aboutus-splash .column-img-container {
  max-height: 550px;
  overflow: hidden; }

.section--aboutus-splash .line-texture {
  position: absolute;
  z-index: -1;
  right: 0;
  width: 30%;
  transform: translate(-20%, 50%); }

.section--aboutus-splash .box--yellow {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  transform: translate(345%, -70%);
  width: 25%;
  height: 50%; }

.section--aboutus-splash .aboutus--textarea {
  transform: translateX(-70px); }

.section--aboutus-splash .white-mask {
  background: white;
  position: absolute;
  transform-origin: left;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px; }

.section--aboutus-splash .box-white-mask {
  background: white;
  position: absolute;
  transform-origin: left;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px; }

.section--aboutus .welcome-line {
  border-bottom: 2px solid black;
  height: 45px; }

.section--aboutus .header-text-box {
  max-width: 350px; }

.section--greendesign .line-texture {
  position: absolute;
  z-index: -1;
  top: 10%;
  left: 0;
  width: 35%; }

.section--greendesign .greendesign-bg {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 90%;
  justify-content: center;
  max-height: 600px;
  overflow: hidden; }
  @media all and (max-width: 768px) {
    .section--greendesign .greendesign-bg {
      width: 100%; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .section--greendesign .greendesign-bg img {
      width: 100%; } }

.section--greendesign .box--green {
  position: absolute;
  top: 50%;
  right: 65%;
  z-index: -2;
  width: 35%;
  height: 55%;
  overflow: hidden; }

.section--greendesign li {
  font-size: 14px;
  font-weight: 400; }

.section--msc .header--msc span:first-child {
  margin-left: -40px;
  margin-right: 20px;
  height: 45px;
  border-bottom: 2px solid black; }

.section--msc .msc-bg {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 90%; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .section--msc .msc-bg img {
      width: 100%; } }

.section--msc .box--blue {
  position: absolute;
  z-index: -2;
  width: 35%;
  height: 55%;
  overflow: hidden;
  top: 30%;
  left: 65%; }

.section--msc .line-texture {
  position: relative;
  z-index: -1;
  top: 0%;
  left: 55%;
  width: 40%; }

.section--award {
  position: relative; }
  .section--award .award-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 90%;
    margin-left: auto; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .section--award .award-bg img {
        width: 100%; } }
  .section--award .box--red {
    position: absolute;
    z-index: -2;
    width: 35%;
    height: 45%;
    overflow: hidden;
    top: 20%;
    right: 65%; }
  .section--award .line-texture {
    position: absolute;
    top: 10%;
    left: -10%;
    z-index: -1;
    width: 40%; }
  .section--award .header-underline-right {
    max-width: 200px; }

.section--experience-menu {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%; }
  .section--experience-menu .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px; }
  .section--experience-menu .container-header-experience {
    overflow: hidden; }
  .section--experience-menu .header-experience {
    padding-top: 125px; }
    .section--experience-menu .header-experience span {
      color: white;
      padding-left: 135px; }
    .section--experience-menu .header-experience .header-underline-right {
      width: 125px; }
  .section--experience-menu .subpage-experience {
    padding-top: 225px;
    padding-bottom: 10px;
    padding-left: 0;
    line-height: 18px; }
    .section--experience-menu .subpage-experience p {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      color: white;
      font-weight: 400;
      letter-spacing: 2px; }
    .section--experience-menu .subpage-experience .header-underline-left {
      height: 15px;
      border-bottom: 1px solid white;
      max-width: 200px;
      transform-origin: left;
      width: unset; }
  .section--experience-menu a {
    text-decoration: none;
    color: white; }

.section--experience-menu-minimal {
  width: 100vw;
  margin-top: 100px;
  margin-bottom: 20px; }
  .section--experience-menu-minimal .experience-select {
    display: inline; }
    .section--experience-menu-minimal .experience-select select {
      box-shadow: none;
      user-select: none;
      border: none;
      outline: none !important;
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      padding: 10px 15px;
      font-size: 1.2rem;
      color: white; }
      .section--experience-menu-minimal .experience-select select option {
        color: black; }
  .section--experience-menu-minimal .experience-select:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none; }
  .section--experience-menu-minimal .experience-select:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 20px;
    right: 30px;
    border-top: 10px solid white; }
  .section--experience-menu-minimal select::-ms-expand {
    display: none; }

.section--all-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 30px; }
  @media all and (max-width: 768px) {
    .section--all-content {
      margin-top: 0;
      padding-left: 0; } }
  .section--all-content .line-texture-container {
    overflow: hidden;
    position: relative;
    padding-top: 15%;
    padding-left: 10%; }
    @media all and (max-width: 768px) {
      .section--all-content .line-texture-container {
        padding: 0; } }
    .section--all-content .line-texture-container .line-texture-img {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0; }
    .section--all-content .line-texture-container img {
      width: 100%;
      height: auto; }
  .section--all-content .all-lifestyle-img-container {
    height: 600px;
    width: 85%;
    margin-left: auto;
    background-size: cover;
    background-position: 75%; }
    @media all and (max-width: 768px) {
      .section--all-content .all-lifestyle-img-container {
        width: 100%;
        height: 400px; } }
  .section--all-content .all-village-img-container {
    overflow: hidden; }
    .section--all-content .all-village-img-container img {
      width: 100%;
      position: relative;
      display: block; }
  .section--all-content .all-commercial-img-container img {
    width: 100%;
    position: relative;
    display: block; }
  .section--all-content .all-residential-img-container img {
    width: 100%;
    position: relative;
    display: block; }
  .section--all-content .all-community-img-container img {
    width: 100%;
    position: relative;
    display: block; }
  .section--all-content .header-all-lifestyle .header-underline-right {
    max-width: 100%; }
  .section--all-content .header-all-village {
    margin-top: 30px; }
    .section--all-content .header-all-village .header-underline-right {
      margin-left: -45px;
      width: 100%;
      height: 160px; }
  .section--all-content .header-all-commercial {
    margin-top: 30px; }
    .section--all-content .header-all-commercial .header-underline-right {
      margin-right: -50px;
      max-width: 100%; }
  .section--all-content .header-all-residential {
    margin-top: 30px; }
    .section--all-content .header-all-residential .header-underline-right {
      max-width: 100%; }
  .section--all-content .header-all-community {
    margin-top: 30px; }
    .section--all-content .header-all-community .header-underline-right {
      margin-right: -50px;
      max-width: 100%;
      height: 102px;
      margin-left: -30px; }
  .section--all-content a {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block; }

.section--lifestyle-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px; }
  @media all and (max-width: 768px) {
    .section--lifestyle-content {
      margin-top: 0;
      padding: 0; } }
  .section--lifestyle-content .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px; }
  .section--lifestyle-content .line-texture-bg {
    overflow: hidden;
    height: 650px;
    width: 40%;
    position: absolute;
    margin-left: -150px;
    z-index: -1; }
  .section--lifestyle-content .content-detail-container {
    background: white;
    border-top: 6px solid black;
    padding-top: 35px;
    position: relative; }
    .section--lifestyle-content .content-detail-container .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; }
  .section--lifestyle-content .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px; }
  .section--lifestyle-content .link-container {
    min-height: 100px;
    display: flex;
    align-items: center; }
    .section--lifestyle-content .link-container a {
      border: 2px solid black;
      letter-spacing: 2px;
      font-size: 12px;
      font-family: "Montserrat";
      font-weight: 600;
      padding: 7px;
      color: black;
      text-decoration: none;
      display: inline-block;
      z-index: 1;
      position: absolute;
      bottom: 32px; }
  .section--lifestyle-content a {
    text-decoration: none;
    color: black; }

.section--commercial-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px; }
  @media all and (max-width: 768px) {
    .section--commercial-content {
      margin-top: 0;
      padding: 0; } }
  .section--commercial-content .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px; }
  .section--commercial-content .line-texture-bg {
    overflow: hidden;
    height: 650px;
    width: 40%;
    position: absolute;
    margin-left: -150px;
    z-index: -1; }
  .section--commercial-content .content-detail-container {
    background: white;
    border-top: 6px solid black;
    padding-top: 35px;
    position: relative; }
    .section--commercial-content .content-detail-container .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; }
  .section--commercial-content .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px; }
  .section--commercial-content .link-container {
    min-height: 100px;
    display: flex;
    align-items: center; }
    .section--commercial-content .link-container a {
      border: 2px solid black;
      letter-spacing: 2px;
      font-size: 12px;
      font-family: "Montserrat";
      font-weight: 600;
      padding: 7px;
      color: black;
      text-decoration: none;
      display: inline-block;
      z-index: 1;
      position: absolute;
      bottom: 32px; }
  .section--commercial-content a {
    color: black;
    text-decoration: none; }
  .section--commercial-content .logos-container {
    width: calc(100% + 15px);
    margin-bottom: 25px; }
  .section--commercial-content .directory-item .item--logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding: 15px;
    border: 1px solid #e6e6e6;
    margin-top: -1px;
    margin-right: -1px; }
    .section--commercial-content .directory-item .item--logo img {
      width: 70%; }
  .section--commercial-content .directory-item .item--content {
    position: relative;
    overflow: hidden;
    width: calc(400% + 1px);
    background: #f2f2f2; }
    .section--commercial-content .directory-item .item--content .content--inner {
      padding: 15px 40px; }
  .section--commercial-content .directory-item {
    background: white; }
    .section--commercial-content .directory-item:nth-child(4n + 1) .item--content {
      left: 0%; }
    .section--commercial-content .directory-item:nth-child(4n + 2) .item--content {
      left: -100%; }
    .section--commercial-content .directory-item:nth-child(4n + 3) .item--content {
      left: -200%; }
    .section--commercial-content .directory-item:nth-child(4n + 4) .item--content {
      left: -300%; }
  @media all and (max-width: 992px) {
    .section--commercial-content .directory-item .item--content {
      width: calc(200% + 1px); }
    .section--commercial-content .directory-item:nth-child(2n + 1) .item--content {
      left: 0%; }
    .section--commercial-content .directory-item:nth-child(2n + 2) .item--content {
      left: -100%; } }

.section--residential-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px; }
  @media all and (max-width: 768px) {
    .section--residential-content {
      margin-top: 0;
      padding: 0; } }
  .section--residential-content .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px; }
  .section--residential-content .line-texture-bg {
    overflow: hidden;
    height: 450px;
    width: 30%;
    position: absolute;
    margin-left: -150px;
    z-index: -1; }
  .section--residential-content .content-detail-container {
    background: white;
    border-top: 6px solid black;
    padding-top: 35px;
    position: relative; }
    .section--residential-content .content-detail-container .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; }
  .section--residential-content .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px; }
  .section--residential-content .link-container {
    min-height: 100px;
    display: flex;
    align-items: center; }
  .section--residential-content a {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block;
    z-index: 1;
    position: absolute;
    bottom: 32px; }

.section--community-amenities-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px; }
  @media all and (max-width: 768px) {
    .section--community-amenities-content {
      margin-top: 0;
      padding: 0; } }
  .section--community-amenities-content .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px; }
  .section--community-amenities-content .line-texture-bg {
    overflow: hidden;
    height: 650px;
    width: 40%;
    position: absolute;
    margin-left: -150px;
    z-index: -1; }
  .section--community-amenities-content .content-detail-container {
    background: white;
    border-top: 6px solid black;
    padding-top: 35px;
    position: relative; }
    .section--community-amenities-content .content-detail-container .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; }
  .section--community-amenities-content .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px; }
  .section--community-amenities-content .link-container {
    min-height: 100px;
    display: flex;
    align-items: center; }
  .section--community-amenities-content a {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block;
    z-index: 1;
    position: absolute;
    bottom: 32px; }

.section--uoa-property-content {
  width: 100%;
  z-index: -1;
  margin-top: 200px;
  padding-left: 225px;
  padding-right: 50px; }
  @media all and (max-width: 768px) {
    .section--uoa-property-content {
      margin-top: 0;
      padding: 0; } }
  @media all and (min-width: 992px) {
    .section--uoa-property-content #gmap-container {
      padding-left: 15px; } }
  @media all and (max-width: 992px) {
    .section--uoa-property-content #gmap-container {
      min-height: 400px; } }
  .section--uoa-property-content .img-container {
    background-clip: content-box !important;
    background-size: cover !important;
    background-position: center !important;
    min-height: 400px; }
  .section--uoa-property-content .img-uoa {
    background: url("../img/experience-page/uoa/bg-uoa1-mobile.jpg");
    background-position-x: 15px !important; }
    @media all and (min-width: 992px) {
      .section--uoa-property-content .img-uoa {
        background: url("../img/experience-page/uoa/bg-uoa1.jpg"); } }
  .section--uoa-property-content .line-texture-bg {
    overflow: hidden;
    height: 450px;
    width: 30%;
    position: absolute;
    margin-left: -150px;
    z-index: -1; }
  .section--uoa-property-content .content-detail-container {
    background: white;
    border-top: 6px solid black;
    padding-top: 35px;
    position: relative; }
    .section--uoa-property-content .content-detail-container .line-texture-container {
      background-image: url("../img/line-texture.png");
      background-size: cover;
      background-position: center;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; }
  .section--uoa-property-content .line-texture-container {
    overflow: hidden;
    height: 50px;
    margin-top: 50px; }
  .section--uoa-property-content .img-detail-container {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 2px; }
  .section--uoa-property-content .link-container {
    min-height: 100px;
    display: flex;
    align-items: center; }
  .section--uoa-property-content a {
    color: black;
    text-decoration: none; }

.content-to-animate {
  opacity: 0; }

h2 {
  font-family: "montserrat";
  font-weight: 600;
  letter-spacing: 5px; }

.section--community-menu {
  position: absolute;
  top: 0; }
  .section--community-menu .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px; }
  .section--community-menu .container-header-community {
    overflow: hidden; }
    .section--community-menu .container-header-community .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px; }
      @media screen and (max-width: 830px) {
        .section--community-menu .container-header-community .header-detail-text {
          display: none; } }
  .section--community-menu .header-community {
    display: flex;
    overflow: hidden;
    padding-top: 125px; }
    .section--community-menu .header-community span {
      color: white;
      padding-left: 25px; }
    .section--community-menu .header-community .header-underline-right {
      width: 125px; }
      @media all and (max-width: 768px) {
        .section--community-menu .header-community .header-underline-right {
          height: 30px; } }
  .section--community-menu .line-texture {
    background-image: url("../img/line-texture.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 30%;
    margin-left: 10%; }

.section--community {
  margin-top: 250px; }
  .section--community .content-detail-container {
    background: white;
    border-top: 6px solid black;
    padding-top: 35px;
    position: relative; }
    @media all and (max-width: 768px) {
      .section--community .content-detail-container {
        padding-bottom: 35px; } }
    .section--community .content-detail-container a {
      color: black;
      text-decoration: none; }
    .section--community .content-detail-container .readmore-link {
      border: 2px solid black;
      letter-spacing: 2px;
      font-size: 12px;
      font-weight: 600;
      padding: 7px;
      color: black;
      text-decoration: none;
      display: inline-block; }
    .section--community .content-detail-container h3 {
      width: 100% !important;
      word-break: break-word; }
  .section--community .highlight-tab-container {
    display: flex;
    justify-content: center;
    margin-right: 8.33333333%;
    padding-top: 10px;
    cursor: pointer; }
    .section--community .highlight-tab-container .highlight-tab {
      display: block;
      border: 1px solid black;
      height: 6px;
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      max-width: 80px; }
      .section--community .highlight-tab-container .highlight-tab.active {
        background: #ffc600;
        border: 1px solid #ffc600; }
  .section--community .article-container {
    justify-content: space-evenly; }
    .section--community .article-container .article {
      max-width: 350px;
      min-width: 350px;
      overflow: hidden;
      margin: 0% 1%; }
      .section--community .article-container .article img {
        align-self: center;
        height: 400px; }
      .section--community .article-container .article .article-title {
        font-weight: 600;
        text-align: center;
        padding: 6px 0px;
        margin-bottom: 7px;
        border-bottom: 1px solid gray;
        text-transform: uppercase; }
      .section--community .article-container .article .article-date {
        text-align: center;
        color: gray; }
      .section--community .article-container .article .article-img {
        height: 350px;
        width: 350px;
        background-size: cover !important;
        background-position: center !important; }
      .section--community .article-container .article a {
        color: black;
        text-decoration: none; }
  @media all and (max-width: 992px) {
    .section--community .highlight-slide {
      min-height: 550px;
      flex-direction: column; }
      .section--community .highlight-slide .img-highlight {
        height: auto;
        width: 100%; } }
  .section--community .highlight-slide .img-highlight {
    height: auto;
    width: 100%; }

.filters-container {
  display: flex;
  align-items: flex-end; }
  @media screen and (max-width: 830px) {
    .filters-container {
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translatex(-50%); } }
  .filters-container a {
    color: black;
    text-decoration: none; }

.section--community-post .featured-image {
  display: none !important; }

.section--community-post iframe {
  width: 100%; }

.year.nav:link {
  font-weight: 500; }

.year.active:link {
  font-weight: 800; }

.section--community-post {
  margin-top: 250px; }
  .section--community-post .post-date p {
    font-weight: 600;
    text-transform: uppercase; }
  .section--community-post .post-title p {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 2px; }
  .section--community-post .featured-image {
    margin-bottom: 15px; }
  .section--community-post .content a {
    color: black;
    text-decoration: none; }
  .section--community-post .related-container {
    margin-top: 50px; }
    .section--community-post .related-container h3 {
      font-weight: 700;
      font-size: 16px;
      display: flex; }
      .section--community-post .related-container h3 .header-underline-right {
        max-width: 55px;
        height: 15px; }
    .section--community-post .related-container a {
      color: black;
      text-decoration: none; }
    .section--community-post .related-container .related-post {
      margin: 15px 20px 0px 0px;
      width: 180px;
      max-width: 100%; }
      .section--community-post .related-container .related-post p {
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 0; }
      .section--community-post .related-container .related-post .posts-img-container {
        overflow: hidden;
        background-position: center;
        background-size: cover;
        width: 180px;
        height: 110px;
        max-width: 100%; }
      .section--community-post .related-container .related-post img {
        width: 100%; }
  .section--community-post .content img {
    width: 100% !important;
    height: auto !important; }

/*---------------------
    Visual Editor
----------------------*/
table, th, tr, td {
  border: 1px solid #ddd; }

.section--directory-menu {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%; }
  .section--directory-menu .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px; }
  .section--directory-menu .container-header-directory {
    overflow: hidden; }
  .section--directory-menu .header-directory {
    overflow: hidden;
    padding-top: 125px; }
    @media all and (max-width: 576px) {
      .section--directory-menu .header-directory {
        width: unset !important; } }
    .section--directory-menu .header-directory span {
      color: white;
      padding-left: 30px; }
      @media all and (max-width: 768px) {
        .section--directory-menu .header-directory span {
          padding-left: 26px; } }
    .section--directory-menu .header-directory .header-underline-right {
      width: 125px; }
      @media all and (max-width: 768px) {
        .section--directory-menu .header-directory .header-underline-right {
          height: 30px; } }
  .section--directory-menu .subpage-directory {
    padding-top: 225px;
    padding-bottom: 10px;
    padding-left: 0;
    line-height: 18px; }
    .section--directory-menu .subpage-directory p {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      color: white;
      font-weight: 400;
      letter-spacing: 2px; }
    .section--directory-menu .subpage-directory .header-underline-left {
      height: 32px;
      border-bottom: 1px solid white;
      max-width: 200px;
      transform-origin: left;
      width: unset; }
  .section--directory-menu a {
    text-decoration: none;
    color: white; }
  .section--directory-menu .header-detail-text {
    font-size: 12px;
    font-weight: 600;
    margin-left: 220px;
    position: relative;
    top: -15px; }
    @media all and (max-width: 576px) {
      .section--directory-menu .header-detail-text {
        display: none; } }

.section--directory {
  margin-top: 250px; }
  .section--directory .logos-container {
    margin-bottom: 25px; }
  .section--directory select {
    background: white;
    border: 1px solid lightgray;
    border-radius: 3px;
    width: 250px;
    height: 40px;
    margin-bottom: 15px;
    text-align-last: center;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* new style */
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat; }
    @media all and (max-width: 576px) {
      .section--directory select {
        width: 90%; } }
  .section--directory #submitBtn {
    margin-left: 15px;
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 7px;
    position: relative;
    top: -2px;
    color: black;
    text-decoration: none; }
  .section--directory .directory-item.is-active .item--logo {
    z-index: 5;
    position: relative;
    -webkit-box-shadow: 0px 0px 30px -5px #7d7d7d;
    -moz-box-shadow: 0px 0px 30px -5px #7d7d7d;
    box-shadow: 0px 0px 30px -5px #7d7d7d; }
  .section--directory .directory-item .item--wrapper .company-name {
    text-align: center;
    font-weight: 600;
    color: #818181;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 11px;
    text-transform: uppercase;
    height: 32px; }
  .section--directory .directory-item .item--logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 170px;
    padding: 15px 15px 0px 15px;
    border: 1px solid #e6e6e6;
    margin-top: -1px;
    margin-right: -1px; }
    .section--directory .directory-item .item--logo img {
      height: 60%;
      transform: scale(1);
      transition: all 0.5s ease-in-out; }
    .section--directory .directory-item .item--logo img:hover {
      transform: scale(1.1);
      transition: all 0.5s ease-in-out; }
  .section--directory .directory-item .item--content {
    position: relative;
    overflow: hidden;
    width: calc(400% + 1px);
    background: #f2f2f2; }
    .section--directory .directory-item .item--content .content--inner {
      display: flex;
      padding: 15px 40px; }
    .section--directory .directory-item .item--content .company-details {
      display: flex;
      flex-direction: column;
      padding: 10px; }
      .section--directory .directory-item .item--content .company-details > p:nth-child(1) {
        font-weight: 600; }
    .section--directory .directory-item .item--content .mini-map img {
      width: 100%; }
  .section--directory .directory-item {
    background: white; }
    .section--directory .directory-item:nth-child(4n + 1) .item--content {
      left: 0%; }
    .section--directory .directory-item:nth-child(4n + 2) .item--content {
      left: -100%; }
    .section--directory .directory-item:nth-child(4n + 3) .item--content {
      left: -200%; }
    .section--directory .directory-item:nth-child(4n + 4) .item--content {
      left: -300%; }
  @media all and (max-width: 992px) {
    .section--directory .directory-item .item--content {
      width: calc(200% + 1px); }
    .section--directory .directory-item:nth-child(2n + 1) .item--content {
      left: 0%; }
    .section--directory .directory-item:nth-child(2n + 2) .item--content {
      left: -100%; } }
  @media all and (max-width: 576px) {
    .section--directory .directory-item .item--content {
      width: 100%;
      left: 0 !important; }
    .section--directory .directory-item .content--inner {
      display: flex;
      flex-direction: column; } }

.section--shuttle-service-menu {
  position: absolute;
  top: 0; }
  .section--shuttle-service-menu .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 295px; }
  .section--shuttle-service-menu .container-header-shuttle-service {
    overflow: hidden; }
    .section--shuttle-service-menu .container-header-shuttle-service .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px; }
  .section--shuttle-service-menu .header-shuttle-service {
    overflow: hidden;
    padding-top: 125px;
    display: flex; }
    @media all and (max-width: 576px) {
      .section--shuttle-service-menu .header-shuttle-service {
        display: none !important; } }
    .section--shuttle-service-menu .header-shuttle-service span {
      color: white;
      padding-left: 57px; }
      @media screen and (max-width: 480px) {
        .section--shuttle-service-menu .header-shuttle-service span {
          padding-left: 0px; } }
    .section--shuttle-service-menu .header-shuttle-service .header-underline-right {
      width: 125px; }
  .section--shuttle-service-menu .line-texture {
    background-image: url("../img/line-texture.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 30%;
    margin-left: 10%; }

.section--shuttle-service-content {
  width: 100%;
  z-index: -1;
  padding-top: 200px;
  padding-left: 225px;
  padding-right: 50px; }
  @media all and (max-width: 768px) {
    .section--shuttle-service-content {
      padding-top: 300px;
      padding-left: 0;
      padding-right: 0; } }
  .section--shuttle-service-content a {
    text-decoration: none;
    color: black; }

.section--parking-menu {
  position: absolute;
  top: 0; }
  .section--parking-menu .texture-box {
    position: absolute;
    background-color: #9ebf35;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 295px; }
  .section--parking-menu .container-header-parking {
    overflow: hidden; }
    .section--parking-menu .container-header-parking .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px; }
  .section--parking-menu .header-parking {
    overflow: hidden;
    padding-top: 80px;
    display: flex; }
    .section--parking-menu .header-parking span {
      color: white;
      padding-left: 45px; }
      @media screen and (max-width: 480px) {
        .section--parking-menu .header-parking span {
          padding-left: 10px; } }
    .section--parking-menu .header-parking .header-underline-right {
      width: 125px; }
      @media all and (max-width: 768px) {
        .section--parking-menu .header-parking .header-underline-right {
          height: 30px; } }
  .section--parking-menu .line-texture {
    background-image: url("../img/line-texture.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 200px;
    z-index: -1;
    height: 400px;
    width: 30%;
    margin-left: 5%; }

a.button-more {
  border: 2px solid black;
  letter-spacing: 2px;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 7px;
  color: black;
  text-decoration: none;
  display: inline-block; }

.navigation-tabs {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  @media all and (max-width: 768px) {
    .navigation-tabs {
      display: flex; } }
  .navigation-tabs .nav-link {
    font-size: 1.2rem;
    font-weight: bold;
    color: lightgray;
    letter-spacing: 2px;
    padding: 1rem 2rem;
    border: 3px solid lightgray;
    border-radius: 0;
    position: relative;
    margin-left: -3px;
    transition: all 0.3s ease; }
    @media all and (max-width: 768px) {
      .navigation-tabs .nav-link {
        font-size: 1rem;
        margin-left: 0; } }
    .navigation-tabs .nav-link:hover {
      color: gray;
      border-color: gray;
      z-index: 2; }
  @media all and (max-width: 768px) {
    .navigation-tabs .nav-item {
      width: 100%;
      margin-bottom: -3px; } }
  .navigation-tabs .nav-item a {
    height: 100%; }
  .navigation-tabs .nav-item:first-child .nav-link {
    margin-left: 0; }
  .navigation-tabs .nav-link.active {
    color: #9ebf35;
    border: 3px solid #9ebf35;
    z-index: 1; }

.page-parking .section--lifestyle-content {
  margin-top: 100px;
  padding-left: 245px; }
  @media all and (max-width: 768px) {
    .page-parking .section--lifestyle-content {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 300px; } }
  .page-parking .section--lifestyle-content .key-visual {
    max-width: 100%; }
    @media all and (min-width: 768px) {
      .page-parking .section--lifestyle-content .key-visual {
        max-width: 110%;
        margin-left: -10%;
        position: relative; } }

.tab-content ul,
.tab-content ol {
  font-size: 14px;
  padding-left: 0; }

.tab-content ul li {
  margin-left: 1.4rem; }

#tab-faq p {
  margin-bottom: 2rem; }

.tab-inner {
  transition: all ease 0.3s; }

.active .tab-inner {
  animation: showUp cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s; }

@keyframes showUp {
  0% {
    opacity: 0;
    transform: translateY(50px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

.infographic-img {
  height: 180px; }

#cashless-ticket .infographic-img {
  height: 220px; }

@media all and (min-width: 768px) {
  .logo-container img {
    max-width: 80px; } }

@media all and (min-width: 992px) {
  .logo-container img {
    max-width: 100%; } }

/* New class */
.steps-icons {
  height: 150px;
  width: auto;
  display: block;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 1rem;
  max-width: 100%; }

.border-rounded {
  border-radius: 5em;
  border-width: 2px;
  border-style: solid;
  padding: 1rem 2rem;
  text-align: center;
  font-weight: 700; }

.border-rounded--parking-green {
  border-color: #9ebf35;
  color: #9ebf35; }

.border-rounded--parking-orange {
  border-color: #fcb029;
  color: #fcb029; }

.page-parking h2 {
  letter-spacing: 1px; }

.box-btn {
  border: 2px solid black;
  padding: 0.5rem 0.3rem;
  display: block;
  text-align: center;
  max-width: 200px; }

.section--search-menu {
  position: absolute;
  top: 0; }
  .section--search-menu .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px; }
  .section--search-menu .container-header-search {
    overflow: hidden; }
    .section--search-menu .container-header-search .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px; }
  .section--search-menu .header-search {
    overflow: hidden;
    padding-top: 125px;
    display: flex; }
    .section--search-menu .header-search span {
      color: white;
      padding-left: 72px; }
    .section--search-menu .header-search .header-underline-right {
      width: 125px; }

.section--search-content {
  padding-top: 200px;
  padding-left: 245px;
  padding-right: 50px;
  min-height: 80vh; }
  @media all and (max-width: 768px) {
    .section--search-content {
      margin-top: 100px;
      padding: 0; } }
  .section--search-content a {
    text-decoration: none;
    color: black; }
  .section--search-content .search-post-readmore {
    border: 2px solid black;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 600;
    padding: 7px;
    color: black;
    text-decoration: none;
    display: inline-block; }
  .section--search-content .search-result:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.section--404-menu {
  position: absolute;
  top: 0; }
  .section--404-menu .texture-box {
    position: absolute;
    background-color: #ac1b24;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 225px;
    height: 475px; }
  .section--404-menu .container-header-404 {
    overflow: hidden; }
    .section--404-menu .container-header-404 .header-detail-text {
      font-size: 12px;
      font-weight: 600;
      margin-left: 220px;
      position: relative;
      top: -5px; }
  .section--404-menu .header-404 {
    overflow: hidden;
    padding-top: 125px;
    display: flex; }
    .section--404-menu .header-404 span {
      color: white;
      padding-left: 102px; }
    .section--404-menu .header-404 .header-underline-right {
      width: 125px; }
      @media all and (max-width: 992px) {
        .section--404-menu .header-404 .header-underline-right {
          display: none; } }

.section--404-content {
  padding-top: 200px;
  padding-left: 245px;
  padding-right: 50px;
  min-height: 400px; }
  @media all and (max-width: 768px) {
    .section--404-content {
      margin-top: 100px;
      padding: 0px; } }
  .section--404-content a {
    text-decoration: none;
    color: black; }
