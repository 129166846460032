.section--community-post {
  margin-top: 250px;
  .post-date p {
    font-weight: 600;
    text-transform: uppercase;
  }
  .post-title p {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .featured-image {
    margin-bottom: 15px;
  }
  .content{
    a {
      color: black;
      text-decoration: none;
    }
  }
  .related-container {
    margin-top: 50px;
    h3 {
      font-weight: 700;
      font-size: 16px;
      display: flex;
      .header-underline-right {
        max-width: 55px;
        height: 15px;
      }
    }
    a {
      color: black;
      text-decoration: none;
    }
    .related-post {
      margin: 15px 20px 0px 0px;
      width: 180px;
      max-width: 100%;
      p {
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .posts-img-container {
        overflow: hidden;
        background-position: center;
        background-size: cover;
        width: 180px;
        height: 110px;
        max-width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  .content{
    img{
      width: 100% !important;
      height: auto !important;
    }
  }
}

/*---------------------
    Visual Editor
----------------------*/

table, th, tr, td {
  border: 1px solid #ddd;
}
